import * as Booking from './Booking';
import * as Internationalisation from './Internationalisation';
import * as Location from './Location';
import * as Payments from './Payments';
import * as Players from './Players';
import * as Sessions from './Sessions';
import * as Sites from './Sites';
import * as UI from './UI';
import * as Games from './Games';
import * as AppSettings from './AppSettingsState';
import * as PurchaseTypes from './PurchaseTypes';
import * as GiftVoucher from './GiftVoucher';
import * as Attribution from './Attribution';

import { Action } from 'redux';

export interface IApplicationState {
    attribution: Attribution.IAttributionState;
    players: Players.IPlayerState;
    sites: Sites.ISiteState;
    location: Location.ILocationState;
    session: Sessions.ISessionState;
    booking: Booking.IBookingState;
    intl: Internationalisation.IInternationalisationState;
    payments: Payments.IPaymentState;
    ui: UI.IUIState;
    games: Games.IGameState;
    appSettings: AppSettings.IAppSettingsState;
    purchaseType: PurchaseTypes.IPurchaseTypeState;
    giftVoucher: GiftVoucher.IGiftVoucherState;
}

export const reducers = {
    attribution: Attribution.reducer,
    players: Players.reducer,
    sites: Sites.reducer,
    location: Location.reducer,
    session: Sessions.reducer,
    booking: Booking.reducer,
    intl: Internationalisation.reducer,
    payments: Payments.reducer,
    ui: UI.reducer,
    games: Games.reducer,
    appSettings: AppSettings.reducer,
    purchaseType: PurchaseTypes.reducer,
    giftVoucher: GiftVoucher.reducer,
};

export interface IAppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => IApplicationState): void;
}

export interface IGoogleTagManagerEvent {
    // Normal Values
    category: string;
    action: string;
    label: string;
    value?: number;

    // Enhanced Values
    event: string;
    eventProps: object;
    // state?: object;
}

export interface IAnalyticsAction extends Action {
    googleTagManagerEvent?: Array<IGoogleTagManagerEvent> | IGoogleTagManagerEvent;
    brazeEvent?: void;
}
