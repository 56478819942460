import './nav.scss';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';

import React from 'react';
import { Layout } from 'antd';
import loadable from '@loadable/component';
import { IApplicationState } from '../../store';

const { Header } = Layout;

const Nav: React.FunctionComponent = () => {
    const logo = `/static/zerolatency-new.png`;

    const LanguageSwitcherComponent = loadable(() => import('../language-switcher/language-switcher'), {
        fallback: <React.Fragment />,
    });

    let trackers: string[] = ['zerolatency'];

    return (
        <Header className="nav-container">
            <div className="nav">
                <ReactGA.OutboundLink
                    eventLabel="Navigate to Main Site"
                    to={`https://zerolatencyvr.com`}
                    target="_top"
                    title="Zero Latency Booking Experience"
                    trackerNames={trackers}
                    className="logo"
                >
                    <img src={logo} alt="Zero Latency Free Roam Virtual Reality" />
                </ReactGA.OutboundLink>
                <LanguageSwitcherComponent />
            </div>
        </Header>
    );
};

export default Nav;
