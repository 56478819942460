import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SessionDatePicker from '../containers/session-date-picker/session-date-picker';
import GamePicker from '../components/game-picker/game-picker';
import ZLSection from '../containers/zl-section/zl-section';
import { actionCreators as SessionActions } from '../store/Sessions';
import { actionCreators as BookingActions, BookingStatus } from '../store/Booking';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState } from '../store';
import { PurchaseTypes } from '../store/PurchaseTypes';

export interface IDatePickerSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const DatePickerSection: React.FunctionComponent<IDatePickerSectionProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { active, scrollTo } = props;

    const locale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const momentLocale = useSelector((state: IApplicationState) => state.intl.momentLocale);
    const loading = useSelector((state: IApplicationState) => state.session.openDatesLoading);
    const availableDates = useSelector((state: IApplicationState) => state.session.openDates);
    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);
    const selectedDate = useSelector((state: IApplicationState) => state.session.selectedDate);
    const selectedGame = useSelector((state: IApplicationState) => state.games.selectedGame);
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    const initalDate = availableDates?.keys()[0];

    const calendarStartDate = useMemo(() => {
        return moment(initalDate).startOf('day');
    }, [initalDate]);

    const monthButtons = useMemo(() => {
        const startDate = calendarStartDate;
        const monthPlus1 = moment(calendarStartDate).add(1, 'M');
        const monthPlus2 = moment(calendarStartDate).add(2, 'M');

        return [
            {
                label: moment().locale(momentLocale).month(startDate.month()).format('MMMM'),
                month: startDate.month(),
                year: startDate.year(),
            },
            {
                label: moment().locale(momentLocale).month(monthPlus1.month()).format('MMMM'),
                month: monthPlus1.month(),
                year: monthPlus1.year(),
            },
            {
                label: moment().locale(momentLocale).month(monthPlus2.month()).format('MMMM'),
                month: monthPlus2.month(),
                year: monthPlus2.year(),
            },
        ];
    }, [calendarStartDate, momentLocale]);

    const onDateChange = useCallback(
        (date: moment.Moment) => {
            if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                dispatch(BookingActions.expireBooking());
                dispatch(SessionActions.clearSelectedDateTime());
            }

            dispatch(SessionActions.postDateSelectedAction(date.locale('en').toISOString(true)));
            dispatch(SessionActions.loadSessionTimes(date.locale('en'), selectedGame?.experienceId));
        },
        [bookingStatus, pendingBooking, dispatch, selectedGame]
    );

    const gamePicker = selectedPurchaseType === PurchaseTypes.Booking ? <GamePicker loading={false} /> : null;

    return (
        <ZLSection
            title={t(
                selectedPurchaseType === PurchaseTypes.Booking
                    ? 'sessionDatePicker.titles.chooseExperienceAndDate'
                    : 'sessionDatePicker.titles.chooseDate'
            )}
            active={active}
            scrollTo={scrollTo}
        >
            <Row justify="center">
                <Col xs={24}>
                    {gamePicker}
                    <SessionDatePicker
                        initalDate={initalDate}
                        selectedDate={selectedDate}
                        availableDates={availableDates}
                        monthHeaderOptions={monthButtons}
                        onDateChange={onDateChange}
                        loading={loading}
                        locale={locale}
                    />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default DatePickerSection;
