import './private-event-type-picker.scss';

import { Col, Row, Select } from 'antd';
import find from 'lodash/find';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Site from '../../interfaces/ISite';
import { IApplicationState } from '../../store';
import { actionCreators as PlayerActions } from '../../store/Players';

export interface ISitePickerProps {
    loading: boolean;
}

const PrivateEventTypePicker: React.FunctionComponent<ISitePickerProps> = (props) => {
    const { loading } = props;
    const { Option } = Select;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedPrivateEventTypeId = useSelector(
        (state: IApplicationState) => state.players.selectedPrivateEventTypeId
    );

    const privateEventTypeChanged = (value: number, option: object[] | object) => {
        dispatch(PlayerActions.selectPrivateEventType(value));
    };

    const PrivateEventTypeOption = (key: string, value: number): React.ReactElement => {
        return (
            <Option className="zl-private-event-type-option" value={value} key={value}>
                {t(key)}
            </Option>
        );
    };

    const items = useMemo(() => {
        const retValue = [];

        retValue.push(PrivateEventTypeOption('privateEventTypePicker.types.corporateEvent', 1));
        retValue.push(PrivateEventTypeOption('privateEventTypePicker.types.bachelorBacheloretteEvent', 2));
        retValue.push(PrivateEventTypeOption('privateEventTypePicker.types.privateEvent', 3));

        return retValue;
    }, []);

    return (
        <React.Fragment>
            <Row justify="start" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Select
                        className="zl-private-event-type-dropdown"
                        dropdownClassName="zl-private-event-type-dropdown-menu"
                        size="large"
                        onChange={privateEventTypeChanged}
                        value={selectedPrivateEventTypeId ?? 1}
                        placeholder={t('sitePicker.control.placeholder')}
                    >
                        {items}
                    </Select>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default React.memo(PrivateEventTypePicker);
