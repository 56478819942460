import './nav-progress.scss';

import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ReactComponent as checkmark } from '../../images/svg/icons-checkmark.svg';
import { ReactComponent as chevron } from '../../images/svg/icons-chevron-right.svg';

interface INavItemProps {
    complete: boolean;
    current: boolean;
    pathName?: string;
    label: string;
}

interface INavContainerProps {
    children: React.ReactNode;
}

const NavProgress: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const page = location.pathname.split('/').filter((o) => o !== '')[1];
    const currentStep =
        page === 'book-now' || page === 'gift-voucher' || page === undefined
            ? 0
            : page === 'checkout'
            ? 1
            : page === 'processing'
            ? 2
            : page === 'complete'
            ? 3
            : -1;

    const NavContainer: React.FunctionComponent<INavContainerProps> = (props) => {
        return <ul className="zl-nav-item-container">{props.children}</ul>;
    };

    const NavItem: React.FunctionComponent<INavItemProps> = (props) => {
        const divider = <Icon component={chevron} className="zl-icon divider" />;
        const icon = props.complete ? <Icon component={checkmark} className="zl-icon green" /> : <React.Fragment />;

        return (
            <li className={`zl-nav-item ${props.complete ? '' : 'incomplete'} ${props.current ? 'current' : ''}`}>
                {icon}
                <Text>{props.label}</Text>
                {divider}
            </li>
        );
    };

    if (currentStep === -1) return <React.Fragment />;

    return (
        <NavContainer>
            <NavItem label={t('nav.progress.selectTickets')} complete={currentStep > 0} current={currentStep === 0} />
            <NavItem label={t('nav.progress.checkout')} complete={currentStep > 1} current={currentStep === 1} />
            <NavItem
                label={t('nav.progress.purchaseComplete')}
                complete={currentStep === 2}
                current={currentStep === 3}
            />
        </NavContainer>
    );
};

export default NavProgress;
