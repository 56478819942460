import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IGiftVoucherConfig } from '../../interfaces/IGiftVoucherConfig';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';
import CurrencyInput from '../currency-input/currency-input';
import './voucher-amount-custom.scss';

export interface IVoucherCustomAmountProps {
    voucherConfig: IGiftVoucherConfig | undefined;
    currencySymbol: string | undefined;
}

const VoucherAmountCustom: React.FunctionComponent<IVoucherCustomAmountProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const min = props.voucherConfig?.minimum ?? 0;
    const max = props.voucherConfig?.maximum ?? 0;
    const currencySymbol = props.currencySymbol ?? '';

    const onAmountChange = useCallback(
        (selectedValue: number) => {
            if (selectedValue > 0) {
                dispatch(GiftVoucherActions.enterAmount(selectedValue));
            }
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <CurrencyInput
                className={`zl-form-item zl-voucher-amount-custom-item`}
                hasFeedback={true}
                fieldDecorator="voucher"
                onChange={(value) => onAmountChange(value)}
                placeHolder={t('giftVoucherSection.purchase.customAmountHeading')}
                minAmount={min}
                maxAmount={max}
                prefix={currencySymbol}
            />
        </React.Fragment>
    );
};

export default VoucherAmountCustom;
