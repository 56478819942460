/// Copied from Global Website

import { Sys, Sys_query } from './SysCM';
import * as RichTextCM from './RichTextCM';

//const CookieSettingTypeName = "CookieSetting";

interface CookieSettingCollection {
    items: CookieSetting[];
}

const CookieSetting_query = `
items {
    heading
    description
    featureToggleName
    alwaysActive
}
`;

export interface CookieSetting {
    heading: string;
    description: string;
    featureToggleName: string;
    alwaysActive: boolean;
}

export const CookieTypeName: string = 'Cookie';
export interface CookieMenu {
    __typename: string;
    sys: Sys;
    policyHeading: string;
    policySubHeading: string;
    policyBody: RichTextCM.RichText;
    settingHeading: string;
    settingSubHeading: string;
    settingBody: RichTextCM.RichText;
    managePreferencesHeading: string;
    viewPolicyText: string;
    viewPolicyUrl: string;
    acceptAllButtonText: string;
    rejectAllButtonText: string;
    manageButtonText: string;
    cookieSettingsCollection: CookieSettingCollection;
    confirmChoicesButtonText: string;
}
export const Cookie_query = `
    __typename
    ${Sys_query}
    policyHeading
    policySubHeading
    policyBody{
        ${RichTextCM.query}
    }
    settingHeading
    settingSubHeading
    settingBody{
        ${RichTextCM.query}
    }
    managePreferencesHeading
    viewPolicyText
    viewPolicyUrl
    acceptAllButtonText
    rejectAllButtonText
    manageButtonText
    cookieSettingsCollection {
        ${CookieSetting_query}
    }
    confirmChoicesButtonText
`;
