import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Checkbox, Col, Row, Modal } from 'antd';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import numberHelpers from '../../helpers/number';

interface IPackagePrivacyProps {
    privacyTypeId: number;
    upgradeCost: number;
    locale: string;
    currency: string;
    showCode?: boolean;
    upgradeChecked: boolean;
    onCheckChanged: (checked: boolean) => void;
}

const PackagePrivacy: React.FunctionComponent<IPackagePrivacyProps> = (props) => {
    const { t } = useTranslation();

    const informationIcon = `/static/information.png`;

    const infoClicked = useCallback(() => {
        const modal = Modal.info({
            icon: undefined,
            maskClosable: true,
            okText: t('packagePicker.labels.close'),
            autoFocusButton: null,
            okType: 'default',
            centered: true,
            title: t('packagePicker.titles.privateSessions'),
            className: 'player-container',
            content: (
                <Paragraph>
                    {t('packagePicker.hint.privateSessions')
                        .split('\n')
                        .map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </Paragraph>
            ),
            onOk: () => {
                modal.destroy();
            },
            onCancel: () => {
                modal.destroy();
            },
        });
    }, []);

    if (props.privacyTypeId === 0 || props.upgradeCost === 0) return null;

    if (props.privacyTypeId === 1) {
        return (
            <Row justify="end" align="bottom" className="upgrade-holder">
                <Col>
                    <Text className="upgrade-text">{t('packagePicker.labels.freePrivateSession')}</Text>
                    <img src={informationIcon} className="scarcity-image" onClick={infoClicked} />
                </Col>
            </Row>
        );
    }

    const formattedValue = numberHelpers.toCurrency(
        props.locale,
        props.currency,
        props.upgradeCost,
        undefined,
        props.showCode
    );

    return (
        <Row justify="end" align="bottom" className="upgrade-holder">
            <Col
                onClick={() => {
                    const upgradeChecked = props.upgradeChecked !== true;
                    props.onCheckChanged(upgradeChecked);
                }}
            >
                <Checkbox
                    className="upgrade-checkbox"
                    checked={props.upgradeChecked}
                    onChange={(e) => props.onCheckChanged(e.target.checked)}
                />
                <Text className="upgrade-text">
                    {t('packagePicker.labels.upgradeToPrivateSession', { price: formattedValue })}
                </Text>
            </Col>
            <Col>
                <img src={informationIcon} className="scarcity-image" onClick={infoClicked} />
            </Col>
        </Row>
    );
};

export default React.memo(PackagePrivacy);
