import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import PlayerCount from '../containers/player-count/player-count';
import ZLSection from '../containers/zl-section/zl-section';
import ZLButton, { ButtonType } from '../components/zl-button/zl-button';
import { ReactComponent as EventIcon } from '../images/svg/icons-event.svg';
import { ReactComponent as ArrowForwards } from '../images/svg/icons-arrow-forward.svg';
import Text from 'antd/lib/typography/Text';
import { IApplicationState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as UIActions } from '../store/UI';

export interface IPlayerPickerProps {
    active: boolean;
    scrollTo?: boolean;
}

const PlayerPicker: React.FunctionComponent<IPlayerPickerProps> = (props) => {
    const { active, scrollTo } = props;
    const { t } = useTranslation();
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const privateEventsAvailable = useSelector(
        (state: IApplicationState) => state.sites.bookingOptions?.privateEventsAvailable
    );
    const currentMoment = useSelector((state: IApplicationState) => state.intl.momentLocale);
    const dispatch = useDispatch();

    const link =
        selectedSite?.groupBookingUrl !== undefined &&
        selectedSite?.groupBookingUrl !== '#' &&
        selectedSite?.groupBookingUrl !== ''
            ? selectedSite.groupBookingUrl
            : `https://zerolatencyvr.com/${currentMoment}/${selectedSite?.urlKey}/events`;

    const CorporateEventElement: React.FunctionComponent = () => {
        return (
            <ZLButton type={ButtonType.Link} href={link} onClick={() => dispatch(UIActions.outboundLink(link))}>
                <Icon className="zl-icon" component={EventIcon} />
                <Text>{t(`playerPicker.events.corporate_${selectedSite?.groupBookingMessageOption}`)}</Text>
                <Icon component={ArrowForwards} />
            </ZLButton>
        );
    };

    const suffix = selectedSite?.hasGroupBookings && !privateEventsAvailable ? <CorporateEventElement /> : undefined;
    return (
        <ZLSection
            title={t('playerPicker.titles.choosePlayers')}
            active={active}
            // suffix={suffix}
            scrollTo={scrollTo}
        >
            <Row justify="start" align="top">
                <Col xs={24}>
                    <PlayerCount />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default React.memo(PlayerPicker);
