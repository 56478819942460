import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../store';

import ZLSection from '../../containers/zl-section/zl-section';
import PurchaseType from '../../components/purchase-type/purchase-type';
import { PurchaseTypes } from '../../store/PurchaseTypes';

export interface IPurchaseTypeSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const PurchaseTypeSection: React.FunctionComponent<IPurchaseTypeSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;

    const hasGiftVoucherAvailable = useSelector(
        (state: IApplicationState) => state.sites.selectedSite?.giftVouchersAvailable ?? true
    );
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    const hideMessage = hasGiftVoucherAvailable || selectedPurchaseType !== PurchaseTypes.GiftVoucher;

    return (
        <React.Fragment>
            <ZLSection title={t('purchaseTypes.heading')} active={active} scrollTo={scrollTo}>
                <PurchaseType />
            </ZLSection>

            <div hidden={hideMessage}>
                <ZLSection active={true}>
                    <Text>Gift Vouchers are currently NOT available at this venue.</Text>
                </ZLSection>
            </div>
        </React.Fragment>
    );
};

export default PurchaseTypeSection;
