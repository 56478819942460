import { Col, Row } from 'antd';
import { push } from 'connected-react-router';
import Text from 'antd/lib/typography/Text';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IApplicationState } from '../../store';
import { actionCreators as PurchaseTypeActions, PurchaseTypes } from '../../store/PurchaseTypes';
import ZLButton, { ButtonSize, ButtonType } from '../zl-button/zl-button';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import { actionCreators as SessionActions } from '../../store/Sessions';
import './purchase-type.scss';
import moment from 'moment';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';

export interface IPurchaseTypeProps {
    onClick?: (num: number) => void;
}

const PurchaseType: React.FunctionComponent<IPurchaseTypeProps> = (props) => {
    const { t } = useTranslation();
    const onClickEvent = props.onClick;
    const dispatch = useDispatch();
    const location = useLocation();

    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);
    const selectedPurchaseTypeUI = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseTypeUI);
    const siteUrlKey = useSelector((state: IApplicationState) => state.sites.selectedSite?.urlKey);
    const privateEventsAvailable = useSelector(
        (state: IApplicationState) => state.sites.bookingOptions?.privateEventsAvailable
    );
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const bookingOptions = useSelector((state: IApplicationState) => state.sites.bookingOptions);
    const accessCode = useSelector((state: IApplicationState) => state.sites.accessCode);
    const selectedGame = useSelector((state: IApplicationState) => state.games.selectedGame);
    const pendingBooking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);
    const discountPercentage = useSelector(
        (state: IApplicationState) => state.giftVoucher.configDetails?.activeDiscount?.discountPercentage
    );
    const currentMoment = useSelector((state: IApplicationState) => state.intl.momentLocale);

    const isLoading = selectedSite?.siteId != bookingOptions?.siteId;
    const pathName = location.pathname;

    const bookingsPackageType = 1;
    const privateEventPackageType = 2;

    const link =
        selectedSite?.groupBookingUrl !== undefined &&
        selectedSite?.groupBookingUrl !== '#' &&
        selectedSite?.groupBookingUrl !== ''
            ? selectedSite.groupBookingUrl
            : `https://zerolatencyvr.com/${currentMoment}/${selectedSite?.urlKey}/events`;

    useEffect(() => {
        if (selectedPurchaseType === PurchaseTypes.PrivateEvent && privateEventsAvailable === false) {
            dispatch(PurchaseTypeActions.selectPurchaseType(PurchaseTypes.Booking));
            dispatch(push(`/${currentMoment}/book-now/${selectedSite?.urlKey}`));
        }
    }, [selectedPurchaseType, privateEventsAvailable]);

    useEffect(() => {
        const page = pathName.split('/').filter((o) => o !== '')[1];
        if (page === 'book-now' && (selectedPurchaseType !== PurchaseTypes.Booking || !selectedPurchaseTypeUI)) {
            dispatch(PurchaseTypeActions.selectPurchaseType(PurchaseTypes.Booking));
        }
        if (
            page === 'gift-voucher' &&
            (selectedPurchaseType !== PurchaseTypes.GiftVoucher || !selectedPurchaseTypeUI)
        ) {
            dispatch(PurchaseTypeActions.selectPurchaseType(PurchaseTypes.GiftVoucher));
        }
        if (
            page === 'private-event' &&
            (selectedPurchaseType !== PurchaseTypes.PrivateEvent || !selectedPurchaseTypeUI)
        ) {
            dispatch(PurchaseTypeActions.selectPurchaseType(PurchaseTypes.PrivateEvent));
        }
    }, [pathName, selectedPurchaseType, selectedPurchaseTypeUI]);

    const buttonSelected = useCallback(
        (selectedType: PurchaseTypes) => {
            if (selectedType === PurchaseTypes.Booking && siteUrlKey) {
                dispatch(push(`/${currentMoment}/book-now/${siteUrlKey}`));
                if (selectedSite) {
                    const start: string = moment().locale('en').format('YYYY-MM-DD');
                    const end: string = moment().locale('en').add(2, 'months').endOf('month').format('YYYY-MM-DD');
                    dispatch(
                        SessionActions.loadOpenDates(
                            selectedSite.siteId,
                            start,
                            end,
                            accessCode,
                            selectedGame?.experienceId,
                            bookingsPackageType
                        )
                    );
                }
            } else if (selectedType === PurchaseTypes.GiftVoucher && siteUrlKey) {
                dispatch(push(`/${currentMoment}/gift-voucher/${siteUrlKey}`));

                if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                    dispatch(BookingActions.expireBooking());
                    dispatch(SessionActions.clearSelectedDateTime());
                }
            } else if (selectedType === PurchaseTypes.PrivateEvent && siteUrlKey) {
                if (privateEventsAvailable) {
                    dispatch(push(`/${currentMoment}/private-event/${siteUrlKey}`));
                    if (selectedSite) {
                        const start: string = moment().locale('en').format('YYYY-MM-DD');
                        const end: string = moment().locale('en').add(2, 'months').endOf('month').format('YYYY-MM-DD');
                        dispatch(
                            SessionActions.loadOpenDates(
                                selectedSite.siteId,
                                start,
                                end,
                                accessCode,
                                selectedGame?.experienceId,
                                privateEventPackageType
                            )
                        );

                        if (pendingBooking && bookingStatus === BookingStatus.Pending) {
                            dispatch(BookingActions.expireBooking());
                            dispatch(SessionActions.clearSelectedDateTime());
                        }
                    }
                } else {
                    window.open(link, '_blank');
                    return;
                }
            }
            dispatch(PurchaseTypeActions.selectPurchaseType(selectedType));
        },
        [
            onClickEvent,
            dispatch,
            selectedPurchaseType,
            siteUrlKey,
            privateEventsAvailable,
            link,
            selectedSite,
            accessCode,
            selectedGame,
            pendingBooking,
            bookingStatus,
        ]
    );

    if (isLoading) return <LoadingSpinner />;

    return (
        <React.Fragment>
            <Row justify="start" align="top" className="zl-purchase-type-container">
                <Col span={24}>
                    <ZLButton
                        type={ButtonType.Default}
                        selected={selectedPurchaseType === PurchaseTypes.Booking}
                        size={ButtonSize.Large}
                        disabled={false}
                        className="zl-purchase-type-button"
                        block
                        tall
                        onClick={() => buttonSelected(PurchaseTypes.Booking)}
                    >
                        <Text>{t('purchaseTypes.bookingLabel')}</Text>
                    </ZLButton>
                    <ZLButton
                        type={ButtonType.Default}
                        selected={selectedPurchaseType === PurchaseTypes.GiftVoucher}
                        size={ButtonSize.Large}
                        disabled={false}
                        className="zl-purchase-type-button"
                        block
                        tall
                        onClick={() => buttonSelected(PurchaseTypes.GiftVoucher)}
                    >
                        <Text>{t('purchaseTypes.giftVoucherLabel')}</Text>
                        {discountPercentage && (
                            <Text className="zl-discount-text">
                                {t('giftVoucherSection.purchase.limitedTimeDeal', {
                                    percentage: discountPercentage * 100,
                                })}
                            </Text>
                        )}
                    </ZLButton>
                    <ZLButton
                        type={ButtonType.Default}
                        selected={selectedPurchaseType === PurchaseTypes.PrivateEvent}
                        size={ButtonSize.Large}
                        disabled={!privateEventsAvailable && !selectedSite?.hasGroupBookings}
                        className="zl-purchase-type-button"
                        block
                        tall
                        onClick={() => buttonSelected(PurchaseTypes.PrivateEvent)}
                    >
                        <Text>{t('purchaseTypes.privateEventLabel')}</Text>
                    </ZLButton>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PurchaseType;
