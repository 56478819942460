import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import VoucherAmountCustom from '../../components/voucher-amount-custom/voucher-amount-custom';
import VoucherAmountFixed from '../../components/voucher-amount-fixed/voucher-amount-fixed';
import ZLSection from '../../containers/zl-section/zl-section';
import { IApplicationState } from '../../store';

export interface IGiftVoucherAmountSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const GiftVoucherAmountSection: React.FunctionComponent<IGiftVoucherAmountSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;

    const site = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const currencySymbol = site?.currencySymbol;

    const voucherConfig = useSelector((state: IApplicationState) => state.giftVoucher.configDetails);
    const loading = useSelector((state: IApplicationState) => state.giftVoucher.configLoading);

    if (loading)
        return (
            <ZLSection title={t('giftVoucherSection.purchase.fixedAmountHeading')} active={active} scrollTo={scrollTo}>
                <LoadingSpinner />;
            </ZLSection>
        );

    return (
        <ZLSection title={t('giftVoucherSection.purchase.fixedAmountHeading')} active={active} scrollTo={scrollTo}>
            <Row justify="start" align="top">
                <Col xs={24}>
                    <VoucherAmountFixed voucherConfig={voucherConfig} />
                </Col>
                <Col span={24}>
                    <Text className={`zl-voucher-amount-text`}>
                        {t('giftVoucherSection.purchase.customAmountHeading')}
                    </Text>
                    <VoucherAmountCustom voucherConfig={voucherConfig} currencySymbol={currencySymbol} />
                </Col>
            </Row>
        </ZLSection>
    );
};

export default GiftVoucherAmountSection;
