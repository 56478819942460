import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RecipientSelector from '../../components/recipient-selector/recipient-selector';
import VoucherDetailInput from '../../components/voucher-detail-input/voucher-detail-input';
import VoucherImage from '../../components/voucher-image/voucher-image';
import ZLSection from '../../containers/zl-section/zl-section';
import { IApplicationState } from '../../store';

export interface IGiftVoucherDetailsSectionProps {
    active: boolean;
    scrollTo?: boolean;
}

const GiftVoucherDetailsSection: React.FunctionComponent<IGiftVoucherDetailsSectionProps> = (props) => {
    const { t } = useTranslation();
    const { active, scrollTo } = props;

    const toRecipient = useSelector((state: IApplicationState) => state.giftVoucher.toRecipientSelected);

    const prefilledEmail = useSelector((state: IApplicationState) => state.giftVoucher.toEmail);
    const prefilledName = useSelector((state: IApplicationState) => state.giftVoucher.toFullName);
    const prefilledMessage = useSelector((state: IApplicationState) => state.giftVoucher.message);

    return (
        <ZLSection title={t('giftVoucherSection.details.headingLabel')} active={active} scrollTo={scrollTo}>
            <RecipientSelector />
            <VoucherDetailInput
                prefilledEmail={prefilledEmail}
                prefilledName={prefilledName}
                prefilledMessage={prefilledMessage}
                hidden={!toRecipient}
            />
            <VoucherImage />
        </ZLSection>
    );
};

export default GiftVoucherDetailsSection;
