import IBooking from '../interfaces/IBooking';
import { ICharge } from '../interfaces/ICharge';
import IConfirmBookingRequest from '../interfaces/IConfirmBookingRequest';
import ICreateBookingRequest from '../interfaces/ICreateBookingRequest';
import IPaymentRequest from '../interfaces/IPaymentRequest';
import ISession from '../interfaces/ISession';
import IUpdateBookingRequest from '../interfaces/IUpdateBookingRequest';
import errorHelpers from '../helpers/errors';
import IBookingDetails from '../interfaces/IBookingDetails';

class Bookings {
    static ExpireBooking = (booking: IBooking): Promise<string> => {
        return fetch(`bookings/${booking.siteId}/${booking.bookingRef}/expire`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.text() as Promise<string>);
    };

    static ReserveBooking = (
        session: ISession,
        players: number,
        emailAddress: string | undefined,
        discountCode: string | undefined,
        adBlockEnabled: boolean,
        privateEventTypeId: number | undefined,
        isPrivateSession: boolean | undefined,
        priceCode: string | undefined
    ): Promise<IPaymentRequest> => {
        const createBookingRequest: ICreateBookingRequest = {
            accessCode: session.accessCode,
            emailAddress: emailAddress,
            packageId: session.packageId,
            sessionName: session.sessionName,
            slots: players,
            discountCode: discountCode,
            adBlockEnabled: adBlockEnabled,
            privateEventTypeId: privateEventTypeId,
            isPrivate: isPrivateSession,
            priceCode: priceCode,
        };

        return fetch(`bookings/${session.siteId}/${session.sessionName}/reserve`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createBookingRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IPaymentRequest>);
    };

    static GetDetails = (bookingRef: string): Promise<IBookingDetails> => {
        return fetch(`bookings/${bookingRef}/summary`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IBookingDetails>);
    };

    static Validate = (bookingRef: string): Promise<IBookingDetails> => {
        return fetch(`bookings/${bookingRef}/validate`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IBookingDetails>);
    };

    static ApplyCode = (booking: IBooking, code: string, email: string): Promise<any> => {
        return fetch(`bookings/${booking.siteId}/${booking.bookingRef}/applycode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                email: email,
            }),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json());
    };

    static RemoveCode = (booking: IBooking, code: string): Promise<any> => {
        return fetch(`bookings/${booking.siteId}/${booking.bookingRef}/removecode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
            }),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json());
    };

    static ConfirmBooking = (
        booking: IBooking,
        charge: ICharge,
        emailAddress: string,
        fullName: string | undefined,
        phoneNumber: string | undefined,
        paymentMethodReference: string,
        paymentMethodTypeId: number,
        paymentGatewayMetadata: any,
        adBlockDetect: boolean
    ): Promise<string> => {
        if (!booking || !charge || !booking.paymentId) throw new Error('Booking and Charge required');

        const confirmRequest: IConfirmBookingRequest = {
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            fullName: fullName,
            amount: charge.total,
            currencyCode: charge.currencyCode,
            dateCreated: new Date().toISOString(),
            paymentGatewayMetadata: paymentGatewayMetadata,
            paymentMethodReference: paymentMethodReference,
            paymentMethodTypeId: paymentMethodTypeId,
            relatedPaymentId: booking.paymentId,
            siteId: booking?.siteId,
            tax: charge?.tax ?? 0,
            adBlockEnabled: adBlockDetect,
        };

        console.log('SENDING CONFIRM REQUEST : with ad blocker value : ' + adBlockDetect);
        var jsn = JSON.stringify(confirmRequest);
        console.log(jsn);

        return fetch(`bookings/${booking.siteId}/${booking.bookingRef}/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsn,
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.text() as Promise<string>);
    };

    static UpdateBooking = (
        booking: IBooking,
        emailAddress: string,
        fullName: string | undefined,
        phoneNumber: string | null,
        bookingCode: string | undefined
    ): Promise<IPaymentRequest> => {
        const updateBookingRequest: IUpdateBookingRequest = {
            emailAddress: emailAddress,
            fullName: fullName,
            phoneNumber: phoneNumber,
            code: bookingCode,
        };

        return fetch(`bookings/${booking.siteId}/${booking.bookingRef}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateBookingRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IPaymentRequest>);
    };
}

export default Bookings;
