import OpenDate from '../interfaces/IOpenDate';
import ISite from '../interfaces/ISite';
import errorHelpers from '../helpers/errors';

class OpenDates {
    static Search = (
        siteId: number,
        start: string,
        end: string,
        accessCode: string | undefined,
        experienceId: number | undefined,
        packageTypeId: number | undefined
    ): Promise<Array<OpenDate>> => {
        return fetch(
            `opendates/${siteId}/${start}/${end}/${accessCode ?? ''}?experienceId=${
                experienceId ? experienceId : ''
            }&packageTypeId=${packageTypeId ? packageTypeId : ''}`
        )
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<Array<OpenDate>>);
    };
}

export default OpenDates;
