import './session-time-picker.scss';

import { Col, Row } from 'antd';
import moment from 'moment';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';

import Hint from '../../components/hint/hint';
import TimePicker from '../../components/time-picker/time-picker';
import { IApplicationState } from '../../store';
import SessionTime from '../../interfaces/ISessionTime';
import { PurchaseTypes } from '../../store/PurchaseTypes';

const SessionTimePicker: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const loading = useSelector((state: IApplicationState) => state.session.sessionTimesLoading);
    const times = useSelector((state: IApplicationState) => state.session.sessionTimes);
    const selectedTime = useSelector((state: IApplicationState) => state.session.selectedTime);
    const selectedPlayers = useSelector((state: IApplicationState) => state.players.selectedPlayers);
    const currentLocale = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const bookingOptions = useSelector((state: IApplicationState) => state.sites.bookingOptions);
    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);

    const sortedTimes = useMemo(() => {
        if (times === undefined) return undefined;

        return sortedUniqBy(
            sortBy(times, (item: SessionTime) => {
                return item.time;
            }),
            (item: SessionTime) => {
                return item.time;
            }
        );
    }, [times]);

    const dayTimes = useMemo(() => {
        if (sortedTimes === undefined) return [];

        return sortedTimes.filter((x: SessionTime) => moment.utc(x.time).hour() < 18);
    }, [sortedTimes]);

    const eveningTimes = useMemo(() => {
        if (sortedTimes === undefined) return [];

        return sortedTimes.filter((x: SessionTime) => moment.utc(x.time).hour() >= 18);
    }, [sortedTimes]);

    const isLoading = selectedSite?.siteId != bookingOptions?.siteId;
    const maximumPlayers = isLoading ? 0 : Math.min(bookingOptions?.maximumPlayers ?? 0, 8);

    return (
        <React.Fragment>
            <Row justify="center" align="top">
                <Col span={24}>
                    <TimePicker
                        loading={loading}
                        dayTimes={dayTimes}
                        eveningTimes={eveningTimes}
                        selectedTime={selectedTime}
                        players={selectedPlayers}
                        locale={currentLocale}
                    />
                </Col>
            </Row>
            <Row
                justify="start"
                align="top"
                hidden={selectedSite?.hasPrivateSessions || selectedPurchaseType === PurchaseTypes.PrivateEvent}
            >
                <Col span={24}>
                    <Hint panelKey="sessiontime" label={t('sessionTimePicker.hint.sessionsExplained')}>
                        <Paragraph>
                            <Trans
                                i18nKey="sessionTimePicker.hint.privateBookings"
                                values={{ maximumPlayers: maximumPlayers }}
                            />
                        </Paragraph>
                    </Hint>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SessionTimePicker;
