import { CallHistoryMethodAction, push } from 'connected-react-router';
import { Action, Reducer } from 'redux';

import Booking from '../interfaces/IBooking';
import { ICharge } from '../interfaces/ICharge';
import { IDiscountCode } from '../interfaces/IDiscountCode';
import Session from '../interfaces/ISession';
import BookingService from '../service/bookings';
import Logging from '../service/logging';
import { IApplicationState, IAppThunkAction, IAnalyticsAction } from './';
import IBookingDetails from '../interfaces/IBookingDetails';
import LogRocket from 'logrocket';
import { setLanguage } from '../helpers/i18n';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';
import _ from 'lodash';
import { PurchaseTypes } from './PurchaseTypes';
import { IPriceSet } from '../interfaces/IPriceSet';
import BrazeEvents from '../helpers/braze';
import IBooking from '../interfaces/IBooking';
// import { appInsights } from "../Logging/appInsights";

export enum BookingStatus {
    Unknown = 0,
    Pending = 1,
    Active = 2,
    Cancelled = 3,
    Refunded = 4,
    Expired = 5,
    Failed = 6,
    Completed = 7,
    Reserved = 8,
}

export enum DiscountCodeResponseType {
    NotFound,
    Inactive,
    Used,
    Available,
    FailedSlotRequirement,
    Expired,
    InvalidClient,
    InvalidSite,
    NotOpenYet,
    InvalidSchedule,
    IncorrectPlayerAccount,
    IncorrectPackageType,
    RedeemLimitExceeded,
    IncorrectAttractionType,
    NoPromoWithGiftVoucher,
}

export interface IBookingState {
    loading: boolean;
    failed: boolean;
    failedMessageKey?: string;
    booking?: Booking;
    charge?: ICharge;
    status: BookingStatus;
    emailAddress?: string;
    fullName?: string;
    countryCode?: string;
    callingCode?: string;
    phoneNumber?: string;
    validPlayerInfo: boolean;
    preSelectedCode?: string;
    appliedCodes: Array<IDiscountCode>;
    codeApplied?: boolean;
    details?: IBookingDetails;
    priceSets?: Array<IPriceSet>;
    emailSubscribed: boolean;
    smsSubscribed: boolean;
    readyForPayment: boolean;
    codeResponse?: DiscountCodeResponseType;
}

export const CLEAR_BOOKING: 'CLEAR_BOOKING' = 'CLEAR_BOOKING';
export const CLEAR_CODE: 'CLEAR_CODE' = 'CLEAR_CODE';

export const VALIDATE_PLAYER_FORM: 'VALIDATE_PLAYER_FORM' = 'VALIDATE_PLAYER_FORM';

export const RECORD_EMAIL_ADDRESS: 'RECORD_EMAIL_ADDRESS' = 'RECORD_EMAIL_ADDRESS';
export const RECORD_CODE: 'RECORD_CODE' = 'RECORD_CODE';
export const RECORD_FULL_NAME: 'RECORD_FULL_NAME' = 'RECORD_FULL_NAME';
export const RECORD_PHONE_NUMBER: 'RECORD_PHONE_NUMBER' = 'RECORD_PHONE_NUMBER';

export const RECORD_EMAIL_SUBSCRIPTION: 'RECORD_EMAIL_SUBSCRIPTION' = 'RECORD_EMAIL_SUBSCRIPTION';
export const RECORD_SMS_SUBSCRIPTION: 'RECORD_SMS_SUBSCRIPTION' = 'RECORD_SMS_SUBSCRIPTION';

export const DISCOUNT_CODE_LOADING: 'DISCOUNT_CODE_LOADING' = 'DISCOUNT_CODE_LOADING';
export const DISCOUNT_CODE_APPLIED: 'DISCOUNT_CODE_APPLIED' = 'DISCOUNT_CODE_APPLIED';
export const DISCOUNT_CODE_REMOVED: 'DISCOUNT_CODE_REMOVED' = 'DISCOUNT_CODE_REMOVED';
export const APPLY_CODE_FAILED: 'APPLY_CODE_FAILED' = 'APPLY_CODE_FAILED';
export const PRESELECT_CODE: 'PRESELECT_CODE' = 'PRESELECT_CODE';

export const CONFIRM_BOOKING_REQUESTED: 'CONFIRM_BOOKING_REQUESTED' = 'CONFIRM_BOOKING_REQUESTED';
export const CONFIRM_BOOKING_SUCCESS: 'CONFIRM_BOOKING_SUCCESS' = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_FAILED: 'CONFIRM_BOOKING_FAILED' = 'CONFIRM_BOOKING_FAILED';
export const CONFIRM_BOOKING_COMPLETE: 'CONFIRM_BOOKING_COMPLETE' = 'CONFIRM_BOOKING_COMPLETE';

export const BOOKING_EXPIRED: 'BOOKING_EXPIRED' = 'BOOKING_EXPIRED';

export const EXPIRE_BOOKING_REQUESTED: 'EXPIRE_BOOKING_REQUESTED' = 'EXPIRE_BOOKING_REQUESTED';
export const EXPIRE_BOOKING_SUCCESS: 'EXPIRE_BOOKING_SUCCESS' = 'EXPIRE_BOOKING_SUCCESS';
export const EXPIRE_BOOKING_FAILED: 'EXPIRE_BOOKING_FAILED' = 'EXPIRE_BOOKING_FAILED';
export const EXPIRE_BOOKING_COMPLETE: 'EXPIRE_BOOKING_COMPLETE' = 'EXPIRE_BOOKING_COMPLETE';

export const UPDATE_BOOKING_REQUESTED: 'UPDATE_BOOKING_REQUESTED' = 'UPDATE_BOOKING_REQUESTED';
export const UPDATE_BOOKING_SUCCESS: 'UPDATE_BOOKING_SUCCESS' = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILED: 'UPDATE_BOOKING_FAILED' = 'UPDATE_BOOKING_FAILED';
export const UPDATE_BOOKING_COMPLETE: 'UPDATE_BOOKING_COMPLETE' = 'UPDATE_BOOKING_COMPLETE';

export const RESERVE_BOOKING_REQUESTED: 'RESERVE_BOOKING_REQUESTED' = 'RESERVE_BOOKING_REQUESTED';
export const RESERVE_BOOKING_SUCCESS: 'RESERVE_BOOKING_SUCCESS' = 'RESERVE_BOOKING_SUCCESS';
export const RESERVE_BOOKING_FAILED: 'RESERVE_BOOKING_FAILED' = 'RESERVE_BOOKING_FAILED';
export const RESERVE_BOOKING_COMPLETE: 'RESERVE_BOOKING_COMPLETE' = 'RESERVE_BOOKING_COMPLETE';

export const BOOKING_DETAILS_LOADING: 'BOOKING_DETAILS_LOADING' = 'BOOKING_DETAILS_LOADING';
export const BOOKING_DETAILS_LOADED: 'BOOKING_DETAILS_LOADED' = 'BOOKING_DETAILS_LOADED';

export const CHECK_BOOKING_LOADED: 'CHECK_BOOKING_LOADED' = 'CHECK_BOOKING_LOADED';

export const MODIFY_BOOKING: 'MODIFY_BOOKING' = 'MODIFY_BOOKING';

export const UPDATE_PRICE_SETS: 'UPDATE_PRICE_SETS' = 'UPDATE_PRICE_SETS';

export const CHANGE_USER: 'CHANGE_USER' = 'CHANGE_USER';

export interface IClearBookingAction extends Action {
    type: typeof CLEAR_BOOKING;
}

export interface IClearCodeAction extends Action {
    type: typeof CLEAR_CODE;
}

export interface IBookingDetailsLoadingAction extends IAnalyticsAction {
    type: typeof BOOKING_DETAILS_LOADING;
}
export interface IBookingDetailsLoadedAction extends IAnalyticsAction {
    type: typeof BOOKING_DETAILS_LOADED;
    details?: IBookingDetails;
    success: boolean;
}

export interface ICheckBookingLoadedAction extends Action {
    type: typeof CHECK_BOOKING_LOADED;
    details?: IBookingDetails;
    success: boolean;
}

export interface IModifyBookingAction extends IAnalyticsAction {
    type: typeof MODIFY_BOOKING;
}

export interface IExpireBookingRequestedAction extends Action {
    type: typeof EXPIRE_BOOKING_REQUESTED;
}
export interface IExpireBookingSuccessAction extends Action {
    type: typeof EXPIRE_BOOKING_SUCCESS;
}
export interface IExpireBookingFailedAction extends Action {
    type: typeof EXPIRE_BOOKING_FAILED;
}
export interface IExpireBookingCompletedAction extends Action {
    type: typeof EXPIRE_BOOKING_COMPLETE;
}

export interface IExpiredBookingAction extends IAnalyticsAction {
    type: typeof BOOKING_EXPIRED;
    bookingRef: string;
    bookingId: number;
}

export interface IReserveBookingRequestedAction extends Action {
    type: typeof RESERVE_BOOKING_REQUESTED;
}

export interface IReserveBookingSuccessAction extends IAnalyticsAction {
    type: typeof RESERVE_BOOKING_SUCCESS;
    booking: Booking;
    charge: ICharge;
    appliedCodes: Array<IDiscountCode>;
}

export interface IReserveBookingFailedAction extends Action {
    type: typeof RESERVE_BOOKING_FAILED;
    failureMessageKey: string;
}

export interface IReserveBookingCompletedAction extends Action {
    type: typeof RESERVE_BOOKING_COMPLETE;
}

export interface IUpdateBookingRequestedAction extends Action {
    type: typeof UPDATE_BOOKING_REQUESTED;
    clientSecret?: string;
}

export interface IUpdateBookingSuccessAction extends IAnalyticsAction {
    type: typeof UPDATE_BOOKING_SUCCESS;
}

export interface IUpdateBookingFailedAction extends Action {
    type: typeof UPDATE_BOOKING_FAILED;
    failureMessageKey: string;
}

export interface IUpdateBookingCompletedAction extends Action {
    type: typeof UPDATE_BOOKING_COMPLETE;
}

export interface IConfirmBookingRequestedAction extends Action {
    type: typeof CONFIRM_BOOKING_REQUESTED;
}

export interface IConfirmBookingSuccessAction extends IAnalyticsAction {
    type: typeof CONFIRM_BOOKING_SUCCESS;
    reserved: boolean;
}

export interface IConfirmBookingFailedAction extends Action {
    type: typeof CONFIRM_BOOKING_FAILED;
}

export interface IConfirmBookingCompletedAction extends Action {
    type: typeof CONFIRM_BOOKING_COMPLETE;
}

export interface IRecordEmailAddressAction extends IAnalyticsAction {
    type: typeof RECORD_EMAIL_ADDRESS;
    emailAddress: string;
}
export interface IRecordCodeAction extends Action {
    type: typeof RECORD_CODE;
    code: string;
}
export interface IRecordFullNameAction extends IAnalyticsAction {
    type: typeof RECORD_FULL_NAME;
    fullName: string;
}

export interface IRecordEmailSubscriptionAction extends Action {
    type: typeof RECORD_EMAIL_SUBSCRIPTION;
    isSubscribed: boolean;
}

export interface IRecordSmsSubscriptionAction extends Action {
    type: typeof RECORD_SMS_SUBSCRIPTION;
    isSubscribed: boolean;
}

export interface IRecordPhoneNumberAction extends Action {
    type: typeof RECORD_PHONE_NUMBER;
    phoneNumber: string;
    countryCode: string;
    callingCode: string;
}

export interface IValidatePlayerFormAction extends Action {
    type: typeof VALIDATE_PLAYER_FORM;
    valid: boolean;
}

export interface IApplyCodeFailed extends IAnalyticsAction {
    type: typeof APPLY_CODE_FAILED;
    codeResponse: DiscountCodeResponseType;
}

export interface IDiscountCodeApplied extends IAnalyticsAction {
    type: typeof DISCOUNT_CODE_APPLIED;
    booking: Booking;
    charge: ICharge;
    codes: Array<IDiscountCode>;
}

export interface IDiscountCodeRemoved extends IAnalyticsAction {
    type: typeof DISCOUNT_CODE_REMOVED;
    booking: Booking;
    charge: ICharge;
    codes: Array<IDiscountCode>;
}

export interface IDiscountCodeLoading extends Action {
    type: typeof DISCOUNT_CODE_LOADING;
}

export interface IPreSelectPromoAction extends Action {
    type: typeof PRESELECT_CODE;
    code: string;
}

export interface IUpdatePriceSetsAction extends IAnalyticsAction {
    type: typeof UPDATE_PRICE_SETS;
    priceSets: Array<IPriceSet>;
}

export interface IChangeUserAction extends IAnalyticsAction {
    type: typeof CHANGE_USER;
    booking: IBooking;
}

export type KnownBookingActions =
    | IReserveBookingRequestedAction
    | IReserveBookingSuccessAction
    | IReserveBookingFailedAction
    | IReserveBookingCompletedAction
    | IUpdateBookingRequestedAction
    | IUpdateBookingSuccessAction
    | IUpdateBookingFailedAction
    | IUpdateBookingCompletedAction
    | IConfirmBookingRequestedAction
    | IConfirmBookingSuccessAction
    | IConfirmBookingFailedAction
    | IConfirmBookingCompletedAction
    | IExpiredBookingAction
    | IExpireBookingRequestedAction
    | IExpireBookingSuccessAction
    | IExpireBookingFailedAction
    | IExpireBookingCompletedAction
    | IPreSelectPromoAction
    | IRecordCodeAction
    | IRecordFullNameAction
    | IRecordEmailAddressAction
    | IRecordPhoneNumberAction
    | IRecordEmailSubscriptionAction
    | IRecordSmsSubscriptionAction
    | IValidatePlayerFormAction
    | IApplyCodeFailed
    | IDiscountCodeApplied
    | IDiscountCodeRemoved
    | IDiscountCodeLoading
    | IModifyBookingAction
    | IClearBookingAction
    | IClearCodeAction
    | IBookingDetailsLoadedAction
    | IBookingDetailsLoadingAction
    | CallHistoryMethodAction
    | ICheckBookingLoadedAction
    | IUpdatePriceSetsAction
    | IChangeUserAction;

export const actionCreators = {
    continuePendingBooking: (): IAppThunkAction<KnownBookingActions> => (dispatch, getState) => {
        const appState = getState();
        const session = appState.session.selectedSession;
        const booking = appState.booking.booking;
        const isEventBooking = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.PrivateEvent;
        const charge = appState.booking.charge;
        const selectedSite = appState.sites.selectedSite;
        const currentLocale = appState.intl.currentLocale;
        const packageName = session?.package.alias ?? 'N/A';

        dispatch(push(`/${appState.intl.momentLocale}/checkout/${appState.sites.selectedSite?.urlKey}`));

        if (charge && booking && selectedSite) {
            const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
            const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

            BrazeEvents.CheckoutInitiated(
                isEventBooking,
                charge,
                selectedSite,
                currentLocale,
                isReservation,
                isGiftVoucher,
                packageName,
                booking
            );
        }
    },

    modifyBooking:
        (booking: Booking): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            // This is the dumbest thing I have had to do to date. This was not Scott. This was JZ.
            // Take this code out when they fix the stupid calendar control.
            setLanguage({ code: 'en-US', displayText: 'English (US)', rtl: false, momentLocale: 'en' });
            const appState = getState();
            // -- End Stupid Code.

            dispatch({
                type: MODIFY_BOOKING,
                googleTagManagerEvent: GoogleTagManagerEvents.ModifiedBooking(booking),
                brazeEvent: BrazeEvents.ModifyCart(),
            } as IModifyBookingAction);

            if (appState.purchaseType.selectedPurchaseType === PurchaseTypes.Booking) {
                dispatch(push(`/${appState.intl.momentLocale}/book-now/${appState.sites.selectedSite?.urlKey}`));
            } else {
                dispatch(push(`/${appState.intl.momentLocale}/private-event/${appState.sites.selectedSite?.urlKey}`));
            }
        },

    validPlayerForm:
        (valid: boolean): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState = getState();

            if (valid && appState.booking.emailAddress) {
                const uid = `${appState.booking.emailAddress}`;
                LogRocket.identify(uid, { emailAddress: appState.booking.emailAddress });
            }

            dispatch({
                type: VALIDATE_PLAYER_FORM,
                valid: valid,
            });
        },

    getBookingDetails:
        (bookingRef: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            if (bookingRef) {
                BookingService.GetDetails(bookingRef)
                    .then((data) => {
                        dispatch({
                            type: BOOKING_DETAILS_LOADED,
                            success: true,
                            details: data,
                        });
                    })
                    .catch((exception) => {
                        dispatch({
                            type: BOOKING_DETAILS_LOADED,
                            success: false,
                            details: undefined,
                        });

                        // appInsights?.trackException(
                        //     { error: new Error(`Error getting booking details`), severityLevel: 2},
                        //     { exception: exception, sourceFile: "Booking.tsx", product: "booking" });
                    });
            }

            dispatch({ type: BOOKING_DETAILS_LOADING });
        },

    checkBookingExpired:
        (bookingRef: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            if (bookingRef) {
                BookingService.GetDetails(bookingRef)
                    .then((data) => {
                        const appState = getState();
                        if (!_.isEqual(appState.booking.details, data)) {
                            dispatch({
                                type: CHECK_BOOKING_LOADED,
                                success: true,
                                details: data,
                            });
                        }
                    })
                    .catch((exception) => {
                        dispatch({
                            type: CHECK_BOOKING_LOADED,
                            success: false,
                            details: undefined,
                        });

                        // appInsights?.trackException(
                        //     { error: new Error(`Error checking booking expired`), severityLevel: 2},
                        //     { exception: exception, sourceFile: "Booking.tsx", product: "booking" });
                    });
            }
        },

    bookingExpired: (bookingRef: string, bookingId: number) =>
        ({
            type: BOOKING_EXPIRED,
            bookingRef: bookingRef,
            bookingId: bookingId,
            googleTagManagerEvent: GoogleTagManagerEvents.BookingExpired(bookingRef, bookingId),
        } as IExpiredBookingAction),

    validateBooking:
        (onValidationSuccess: any, onValidationFailed: any): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            const booking = appState.booking.booking;
            if (booking) {
                BookingService.Validate(booking.bookingRef)
                    .then((data) => {
                        if (data.bookingStatusTypeId !== 5) {
                            onValidationSuccess();
                        } else {
                            onValidationFailed();
                        }
                        dispatch({
                            type: BOOKING_DETAILS_LOADED,
                            success: true,
                            details: data,
                        });
                    })
                    .catch((exception) => {
                        onValidationFailed();
                        // appInsights?.trackException(
                        //     { error: new Error(`Error on validate booking`), severityLevel: 2},
                        //     { exception: exception, sourceFile: "Booking.tsx", product: "booking" });
                    });
            }
        },

    reserveBooking:
        (session: Session, adBlockEnabled: boolean): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            const session = appState.session.selectedSession; //ui selection
            const playerCount = appState.players.selectedPlayers; //ui selection
            const isEventBooking = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.PrivateEvent; //ui selection
            const privateEventType = appState.players.selectedPrivateEventTypeId ?? 1; // Default value is one for this UI
            const isPrivateSession =
                appState.players.selectedPrivateUpgrade && (session?.package.privacyTypeId ?? 0) > 0;
            const priceSet = appState.booking.priceSets?.find((p) => p.venue === appState.sites.selectedSite?.urlKey);
            const packageName = session?.package.alias ?? 'N/A';

            const selectedSite = appState.sites.selectedSite;
            const currentLocale = appState.intl.currentLocale;

            const isReservation = appState?.sites?.selectedSite?.reservationPortal ?? false;
            const isGiftVoucher = appState?.purchaseType?.selectedPurchaseType === PurchaseTypes.GiftVoucher;

            if (session && playerCount && selectedSite) {
                const emailAddress = appState.booking.emailAddress;
                const discountCode = appState.booking.preSelectedCode;

                BookingService.ReserveBooking(
                    session,
                    playerCount,
                    emailAddress,
                    discountCode,
                    adBlockEnabled,
                    privateEventType,
                    isPrivateSession,
                    priceSet?.code
                )
                    .then((data) => {
                        console.log();
                        dispatch({
                            type: RESERVE_BOOKING_SUCCESS,
                            booking: data.booking,
                            charge: data.charge,
                            appliedCodes: data.discountCode,
                            googleTagManagerEvent: GoogleTagManagerEvents.ReserveBooking(data.charge),
                            brazeEvent: BrazeEvents.CheckoutInitiated(
                                isEventBooking,
                                data.charge,
                                selectedSite,
                                currentLocale,
                                isReservation,
                                isGiftVoucher,
                                packageName,
                                data.booking
                            ),
                        });
                        Logging.SetPaymentId(data.booking.paymentId?.toString());
                        dispatch(
                            push(`/${appState.intl.momentLocale}/checkout/${appState.sites.selectedSite?.urlKey}`)
                        );
                    })

                    .catch((error: Error) => {
                        dispatch({
                            type: RESERVE_BOOKING_FAILED,
                            failureMessageKey: 'checkout failed',
                        });
                        // appInsights?.trackException(
                        //     { error: new Error(`Error reserving booking`), severityLevel: 2},
                        //     { exception: error, sourceFile: "Booking.tsx", product: "booking" });
                    });

                dispatch({ type: RESERVE_BOOKING_REQUESTED });
            }
        },

    confirmBooking:
        (
            paymentGatewayMetadata: any,
            paymentMethodReference: string,
            paymentMethodTypeId: number,
            adBlockDetected: boolean
        ): IAppThunkAction<any> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();

            if (appState && appState.booking.booking && appState.booking.charge && appState.booking.emailAddress) {
                const booking = appState.booking.booking;
                const charge = appState.booking.charge;
                const emailAddress = appState.booking.emailAddress;
                const fullName = appState.booking.fullName;
                const callingCode = appState.booking.callingCode;
                const phoneNumber = callingCode + ' ' + appState.booking.phoneNumber;

                //move to processing page before starting
                dispatch(push(`/${appState.intl.momentLocale}/processing`));

                BookingService.ConfirmBooking(
                    booking,
                    charge,
                    emailAddress,
                    fullName,
                    phoneNumber,
                    paymentMethodReference,
                    paymentMethodTypeId,
                    paymentGatewayMetadata,
                    adBlockDetected
                )
                    .then((data) => {
                        dispatch(actionCreators.confirmBookingSuccess());
                    })
                    .catch((error: Error) => {
                        dispatch({ type: CONFIRM_BOOKING_FAILED });
                        // appInsights?.trackException(
                        //     { error: new Error(`Error confirming booking`), severityLevel: 2},
                        //     { exception: error, sourceFile: "Booking.tsx", product: "booking" });
                    });

                dispatch({
                    type: CONFIRM_BOOKING_COMPLETE,
                });
            }

            dispatch({ type: CONFIRM_BOOKING_REQUESTED });
        },

    confirmBookingSuccess: (): IAppThunkAction<KnownBookingActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const booking = appState.booking.booking;
        const charge = appState.booking.charge;
        const session = appState.session.selectedSession;

        if (session && charge && booking) {
            const isReservationSite = appState.sites.selectedSite?.reservationPortal;
            const siteCode = appState.sites.selectedSite?.code ?? '';
            const gtmKey = appState.sites.selectedSiteGtmKey;
            const appliedCodes = appState.booking.appliedCodes;

            dispatch({
                type: CONFIRM_BOOKING_SUCCESS,
                reserved: isReservationSite ?? false,
                googleTagManagerEvent: GoogleTagManagerEvents.ConfirmBooking(
                    charge,
                    session,
                    booking,
                    siteCode,
                    appliedCodes,
                    gtmKey
                ),
            });

            dispatch(push(`/${appState.intl.momentLocale}/complete/${appState.sites.selectedSite?.urlKey}`));
        }
    },

    expireBooking: (): IAppThunkAction<KnownBookingActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();
        const booking = appState.booking.booking;
        const status = appState.booking.status;
        if (booking && status === BookingStatus.Pending) {
            BookingService.ExpireBooking(booking)
                .then((response) => {
                    dispatch({ type: EXPIRE_BOOKING_SUCCESS });
                    dispatch({ type: EXPIRE_BOOKING_COMPLETE });
                })

                .catch((error: Error) => {
                    dispatch({ type: EXPIRE_BOOKING_FAILED });
                    dispatch({ type: EXPIRE_BOOKING_COMPLETE });
                    // appInsights?.trackException(
                    //     { error: new Error(`Error expiring booking`), severityLevel: 2},
                    //     { exception: error, sourceFile: "Booking.tsx", product: "booking" });
                });
        }

        dispatch({ type: EXPIRE_BOOKING_REQUESTED });
    },

    clearBooking: (): IAppThunkAction<KnownBookingActions> => (dispatch) => {
        Logging.SetPaymentId('');
        dispatch({ type: CLEAR_BOOKING });
    },

    recordEmailAddress:
        (emailAddress: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            dispatch({
                type: RECORD_EMAIL_ADDRESS,
                emailAddress: emailAddress,
                googleTagManagerEvent: GoogleTagManagerEvents.RecordEmailAddress(),
            } as IRecordEmailAddressAction);
        },

    recordPhoneNumber: (phoneNumber: string, countryCode: string, callingCode: string) =>
        ({
            type: RECORD_PHONE_NUMBER,
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            callingCode: callingCode,
            googleTagManagerEvent: GoogleTagManagerEvents.RecordPhoneNumber(),
        } as IRecordPhoneNumberAction),

    recordPreselectedCode:
        (code: string | null): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            if (code !== null && code !== '' && appState.booking.preSelectedCode !== code)
                dispatch({ type: PRESELECT_CODE, code: code });
        },

    recordCode:
        (code: string, email: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            if (appState.booking.booking) {
                const booking = appState.booking.booking;
                BookingService.ApplyCode(booking, code, email)
                    .then((data) => {
                        const codeResponse = data.discountCodeResponseType as DiscountCodeResponseType;
                        if (
                            codeResponse === DiscountCodeResponseType.Available &&
                            data.booking &&
                            data.charge &&
                            data.discountCode
                        ) {
                            dispatch({
                                type: DISCOUNT_CODE_APPLIED,
                                booking: data.booking,
                                charge: data.charge,
                                codes: data.discountCode,
                                googleTagManagerEvent: GoogleTagManagerEvents.AppliedDiscountCode(code),
                                brazeEvent: BrazeEvents.DiscountAdded(data.discountCode),
                            });
                        } else {
                            dispatch({
                                type: APPLY_CODE_FAILED,
                                googleTagManagerEvent: GoogleTagManagerEvents.InvalidDiscountCode(code),
                                codeResponse: codeResponse,
                            });
                        }
                    })
                    .catch((error: Error) => {
                        dispatch({
                            type: APPLY_CODE_FAILED,
                            googleTagManagerEvent: GoogleTagManagerEvents.InvalidDiscountCode(code),
                            codeResponse: DiscountCodeResponseType.NotFound,
                        });
                        // appInsights?.trackException(
                        //     { error: new Error(`Error applying code to booking`), severityLevel: 2},
                        //     { exception: error, sourceFile: "Booking.tsx", product: "booking" });
                    });

                dispatch({
                    type: DISCOUNT_CODE_LOADING,
                });
            }
        },

    removeCode:
        (code: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            if (appState.booking.booking) {
                const booking = appState.booking.booking;
                BookingService.RemoveCode(booking, code).then((data) => {
                    dispatch({
                        type: DISCOUNT_CODE_REMOVED,
                        booking: data.booking,
                        charge: data.charge,
                        codes: data.discountCode ?? [],
                        googleTagManagerEvent: GoogleTagManagerEvents.RemovedDiscountCode(code),
                    });
                });

                dispatch({
                    type: DISCOUNT_CODE_LOADING,
                });
            }
        },

    clearCode: () => ({ type: CLEAR_CODE } as IClearCodeAction),

    recordFullName:
        (fullName: string, validForm: boolean): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            dispatch({
                type: RECORD_FULL_NAME,
                fullName: fullName,
                googleTagManagerEvent: GoogleTagManagerEvents.RecordFullName(),
            });
        },

    recordEmailSubscribed:
        (isSubscribed: boolean): IAppThunkAction<KnownBookingActions> =>
        (dispatch) => {
            dispatch({
                type: RECORD_EMAIL_SUBSCRIPTION,
                isSubscribed: isSubscribed,
            });
        },

    recordSmsSubscribed:
        (isSubscribed: boolean): IAppThunkAction<KnownBookingActions> =>
        (dispatch) => {
            dispatch({
                type: RECORD_SMS_SUBSCRIPTION,
                isSubscribed: isSubscribed,
            });
        },

    updateBooking: (): IAppThunkAction<KnownBookingActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();

        const emailAddress = appState.booking.emailAddress;
        const fullName = appState.booking.fullName;
        const callingCode = appState.booking.callingCode;
        let phoneNumber: string | null = null;
        if (appState.booking.phoneNumber) {
            phoneNumber = callingCode + ' ' + appState.booking.phoneNumber;
        }

        const bookingCode = appState.booking.booking?.code ?? appState.booking.preSelectedCode;

        const venueId = appState?.sites?.selectedSite?.siteId;

        dispatch({ type: UPDATE_BOOKING_REQUESTED });

        if (appState && appState.booking.booking && emailAddress && venueId) {
            const booking = appState.booking.booking;
            BookingService.UpdateBooking(booking, emailAddress, fullName, phoneNumber, bookingCode).then((data) => {
                if (bookingCode && data.booking && data.charge && data.discountCode.length > 0) {
                    dispatch({
                        type: DISCOUNT_CODE_APPLIED,
                        booking: data.booking,
                        charge: data.charge,
                        codes: data.discountCode,
                        googleTagManagerEvent: GoogleTagManagerEvents.AppliedDiscountCode(bookingCode),
                        brazeEvent: BrazeEvents.DiscountAdded(data.discountCode[0]),
                    });
                }

                dispatch({ type: UPDATE_BOOKING_COMPLETE });
            });
        }
    },

    changeUser: (): IAppThunkAction<KnownBookingActions> => (dispatch, getState) => {
        const appState: IApplicationState = getState();

        const emailAddress = appState.booking.emailAddress;
        const fullName = appState.booking.fullName;
        const callingCode = appState.booking.callingCode;
        let phoneNumber: string | null = null;
        if (appState.booking.phoneNumber) {
            phoneNumber = callingCode + ' ' + appState.booking.phoneNumber;
        }

        const venueId = appState?.sites?.selectedSite?.siteId;
        const venueName = appState?.sites?.selectedSite?.name ?? 'N/A';
        const names = fullName?.split(' ') ?? [];
        const firstName = names[0] ?? '';
        const lastName = names?.slice(1).join(' ') ?? '';

        const bookingCode = appState.booking.booking?.code ?? appState.booking.preSelectedCode;

        const selectedSite = appState.sites.selectedSite;

        const currentLocale = appState.intl.currentLocale;

        if (appState && appState.booking.booking && emailAddress && venueId && selectedSite) {
            const booking = appState.booking.booking;

            dispatch({ type: UPDATE_BOOKING_REQUESTED });

            console.log(`Submitting Discount Code: ${bookingCode}`);
            BookingService.UpdateBooking(booking, emailAddress, fullName, phoneNumber, bookingCode).then((data) => {
                dispatch({
                    type: CHANGE_USER,
                    booking: data.booking,
                    brazeEvent: BrazeEvents.ChangeUser(
                        data.booking.playerGuid,
                        emailAddress,
                        firstName,
                        lastName,
                        venueId,
                        venueName,
                        phoneNumber,
                        selectedSite,
                        currentLocale
                    ),
                });

                console.log(`Discount Code: ${data.discountCode}`);
                if (bookingCode && data.booking && data.charge && data.discountCode.length > 0) {
                    console.log('Discount Code Added');
                    dispatch({
                        type: DISCOUNT_CODE_APPLIED,
                        booking: data.booking,
                        charge: data.charge,
                        codes: data.discountCode,
                        googleTagManagerEvent: GoogleTagManagerEvents.AppliedDiscountCode(bookingCode),
                        brazeEvent: BrazeEvents.DiscountAdded(data.discountCode[0]),
                    });
                }

                dispatch({ type: UPDATE_BOOKING_COMPLETE });
            });
        }
    },

    recordPriceSet:
        (venue: string, setCode: string | null, source: string): IAppThunkAction<KnownBookingActions> =>
        (dispatch, getState) => {
            if (!setCode) return;

            const appState: IApplicationState = getState();

            let newPriceSets = appState.booking.priceSets?.filter((p) => p.venue !== venue);

            if (!newPriceSets) newPriceSets = [];

            newPriceSets.push({ venue: venue, code: setCode });

            dispatch({
                type: UPDATE_PRICE_SETS,
                priceSets: newPriceSets,
                googleTagManagerEvent: GoogleTagManagerEvents.RecordPriceSet(venue, setCode, source),
            });
        },
};

const undefinedState: IBookingState = {
    loading: false,
    failed: false,
    status: BookingStatus.Unknown,
    booking: undefined,
    charge: undefined,
    details: undefined,
    validPlayerInfo: false,
    appliedCodes: [],
    codeApplied: undefined,
    emailSubscribed: false,
    smsSubscribed: false,
    readyForPayment: false,
};

export const reducer: Reducer<IBookingState> = (
    state: IBookingState | undefined,
    incomingAction: Action
): IBookingState => {
    const action: KnownBookingActions = incomingAction as KnownBookingActions;
    if (state === undefined) return undefinedState;

    switch (action.type) {
        case BOOKING_DETAILS_LOADING: {
            return {
                ...state,
                details: undefined,
                loading: true,
                failed: false,
            };
        }
        case BOOKING_DETAILS_LOADED: {
            return {
                ...state,
                details: action.details,
                loading: false,
                failed: !action.success,
            };
        }
        case CHECK_BOOKING_LOADED: {
            var bookingStatus = action.details?.bookingStatusTypeId === 5 ? BookingStatus.Expired : state.status;
            bookingStatus = action.details?.bookingStatusTypeId === 2 ? BookingStatus.Active : bookingStatus;
            return {
                ...state,
                details: action.details,
                loading: false,
                failed: !action.success,
                status: bookingStatus,
            };
        }
        case EXPIRE_BOOKING_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
            };
        }
        case EXPIRE_BOOKING_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                failedMessageKey: undefined,
                booking: undefined,
                charge: undefined,
                emailAddress: undefined,
                fullName: undefined,
                phoneNumber: undefined,
                callingCode: undefined,
                countryCode: undefined,
                status: BookingStatus.Unknown,
                appliedCodes: [],
            };
        }
        case EXPIRE_BOOKING_COMPLETE: {
            return {
                ...state,
                loading: false,
                booking: undefined,
                charge: undefined,
                status: BookingStatus.Unknown,
                appliedCodes: [],
            };
        }
        case APPLY_CODE_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
                codeApplied: false,
                codeResponse: action.codeResponse,
            };
        }
        case DISCOUNT_CODE_APPLIED: {
            return {
                ...state,
                loading: false,
                failed: false,
                booking: action.booking,
                charge: action.charge,
                appliedCodes: action.codes,
                codeApplied: true,
                codeResponse: DiscountCodeResponseType.Available,
            };
        }
        case DISCOUNT_CODE_REMOVED: {
            return {
                ...state,
                loading: false,
                failed: false,
                booking: action.booking,
                charge: action.charge,
                appliedCodes: action.codes,
                codeApplied: undefined,
                codeResponse: undefined,
            };
        }
        case DISCOUNT_CODE_LOADING: {
            return {
                ...state,
                loading: true,
                failed: false,
            };
        }
        case RESERVE_BOOKING_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
                booking: undefined,
                charge: undefined,
                appliedCodes: [],
            };
        }
        case RESERVE_BOOKING_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                booking: action.booking,
                charge: action.charge,
                status: BookingStatus.Pending,
                codeApplied: action.appliedCodes.length > 0 ? true : state.codeApplied,
                appliedCodes: action.appliedCodes,
            };
        }
        case RESERVE_BOOKING_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
                failedMessageKey: action.failureMessageKey,
                booking: undefined,
                charge: undefined,
                appliedCodes: [],
            };
        }
        case VALIDATE_PLAYER_FORM: {
            return {
                ...state,
                loading: false,
                failed: false,
                validPlayerInfo: action.valid,
            };
        }
        case RECORD_EMAIL_ADDRESS: {
            return {
                ...state,
                failed: false,
                emailAddress: action.emailAddress,
            };
        }
        case RECORD_FULL_NAME: {
            return {
                ...state,
                failed: false,
                fullName: action.fullName,
            };
        }
        case RECORD_PHONE_NUMBER: {
            return {
                ...state,
                failed: true,
                phoneNumber: action.phoneNumber,
                countryCode: action.countryCode,
                callingCode: action.callingCode,
            };
        }
        case RECORD_EMAIL_SUBSCRIPTION: {
            return {
                ...state,
                emailSubscribed: action.isSubscribed,
            };
        }
        case RECORD_SMS_SUBSCRIPTION: {
            return {
                ...state,
                smsSubscribed: action.isSubscribed,
            };
        }
        case CHANGE_USER: {
            return {
                ...state,
                booking: action.booking,
            };
        }
        case UPDATE_BOOKING_REQUESTED: {
            return {
                ...state,
                readyForPayment: false,
            };
        }
        case UPDATE_BOOKING_SUCCESS: {
            return {
                ...state,
                failed: false,
            };
        }
        case UPDATE_BOOKING_FAILED: {
            return {
                ...state,
                failed: true,
            };
        }
        case UPDATE_BOOKING_COMPLETE: {
            return {
                ...state,
                loading: false,
                readyForPayment: true,
            };
        }
        case CONFIRM_BOOKING_REQUESTED: {
            return {
                ...state,
                loading: true,
                failed: false,
                status: BookingStatus.Completed,
            };
        }
        case CONFIRM_BOOKING_FAILED: {
            return {
                ...state,
                loading: false,
                failed: true,
                status: BookingStatus.Completed,
            };
        }
        case CONFIRM_BOOKING_SUCCESS: {
            return {
                ...state,
                loading: false,
                failed: false,
                status: action.reserved ? BookingStatus.Reserved : BookingStatus.Completed,
            };
        }
        case PRESELECT_CODE: {
            return {
                ...state,
                preSelectedCode: action.code,
            };
        }
        case CLEAR_CODE: {
            return {
                ...state,
                codeApplied: undefined,
                preSelectedCode: undefined,
            };
        }
        case CLEAR_BOOKING: {
            return {
                ...undefinedState,
                preSelectedCode: state.preSelectedCode,
                priceSets: state.priceSets,
            };
        }
        case UPDATE_PRICE_SETS: {
            return {
                ...state,
                priceSets: action.priceSets,
            };
        }
        default:
            return state;
    }
};
