import IPlayer from '../interfaces/IPlayer';
import IPaymentRequest from '../interfaces/IPaymentRequest';
import errorHelpers from '../helpers/errors';
import IEmailValidationResult from '../interfaces/IEmailValidationResult';

class Players {
    public static Search = (emailAddress: string): Promise<IPlayer> => {
        return fetch(`players/search/${emailAddress}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IPlayer>);
    };
    public static UpdateSelectedTickets = (
        pendingBookingId: number,
        selectedSiteId: number,
        selectedTickets: number
    ): Promise<IPaymentRequest> => {
        return fetch(`bookings/${selectedSiteId}/${pendingBookingId}/set-tickets?tickets=${selectedTickets}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IPaymentRequest>);
    };

    public static ValidateEmail = (emailAddress: string): Promise<IEmailValidationResult> => {
        return fetch(`players/validateEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailAddress),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IEmailValidationResult>);
    };
}

export default Players;
