import './package-picker.scss';

import { Col, Empty, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Hint from '../../components/hint/hint';
import GamePackage from '../../components/game-package/game-package';
import Session from '../../interfaces/ISession';
import { IApplicationState } from '../../store';
import { actionCreators as SessionActions } from '../../store/Sessions';
import { actionCreators as BookingActions, BookingStatus } from '../../store/Booking';
import { actionCreators as PlayerActions } from '../../store/Players';
import PackageTierPrice from '../../interfaces/IPackageTierPrice';

const PackagePicker: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedTime = useSelector((state: IApplicationState) => state.session.selectedTime);
    const currentLanguage = useSelector((state: IApplicationState) => state.intl.currentLocale);
    const booking = useSelector((state: IApplicationState) => state.booking.booking);
    const bookingStatus = useSelector((state: IApplicationState) => state.booking.status);
    const selectedSession = useSelector((state: IApplicationState) => state.session.selectedSession);
    const sessions = useSelector((state: IApplicationState) => state.session.sessions);
    const site = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const selectedPlayers = useSelector((state: IApplicationState) => state.players.selectedPlayers);
    const selectedPrivateUpgrade = useSelector((state: IApplicationState) => state.players.selectedPrivateUpgrade);
    const priceSets = useSelector((state: IApplicationState) => state.booking.priceSets);

    const priceSet = priceSets?.find((p) => p.venue === site?.urlKey);

    const emptyLogo = `/static/zl-black-new.png`;

    const filteredSessions = useMemo(() => {
        if (!selectedTime || !sessions) return [];
        var session = sessions.getValue(selectedTime.time);
        if (session && selectedPlayers) {
            for (var i = session.length - 1; i >= 0; i--) {
                if (
                    session[i] &&
                    (session[i].remainingSlots < selectedPlayers ||
                        (session[i].openTimeMinimumPlayers > selectedPlayers && session[i].bookedSlots === 0) ||
                        session[i].isPrivate)
                ) {
                    session.splice(i, 1);
                }
            }
        }
        return session;
    }, [selectedTime, sessions, selectedPlayers]);

    const sessionSelected = useCallback(
        (session: Session) => {
            if (booking && bookingStatus === BookingStatus.Pending && booking.packageId !== session.packageId) {
                dispatch(BookingActions.expireBooking());
            }

            dispatch(SessionActions.postSessionSelectedAction(session));
        },
        [dispatch, booking, bookingStatus]
    );

    const privacySelected = useCallback(
        (isPrivate: boolean) => {
            if (booking && bookingStatus === BookingStatus.Pending && selectedPrivateUpgrade !== isPrivate) {
                dispatch(BookingActions.expireBooking());
            }

            dispatch(PlayerActions.selectPrivateUpgrade(isPrivate));
        },
        [dispatch, booking, bookingStatus, selectedPrivateUpgrade]
    );

    if (filteredSessions === undefined || filteredSessions.length === 0)
        return (
            <React.Fragment>
                <Row justify="start" align="top">
                    <Col span={24}>
                        <Empty
                            description={t('packagePicker.labels.noSessions')}
                            image={emptyLogo}
                            imageStyle={{
                                opacity: 0.1,
                                height: 50,
                                margin: 20,
                            }}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <Row gutter={8} justify="center">
                <Col span={24}>
                    {filteredSessions.map((session: Session) => {
                        return (
                            <GamePackage
                                key={`${session.sessionName}-${session.packageId}`}
                                session={session}
                                site={site}
                                priceSet={priceSet}
                                isSelected={session === selectedSession}
                                locale={currentLanguage}
                                onClick={sessionSelected}
                                selectedPlayers={selectedPlayers}
                                selectedPrivateUpgrade={selectedPrivateUpgrade}
                                onUpgradeCheckChanged={privacySelected}
                            />
                        );
                    })}
                </Col>
            </Row>
            <Row justify="start" align="top">
                <Col span={24}>
                    <Hint>
                        <Text>{t('packagePicker.hint.additionalPackages')}</Text>
                    </Hint>
                    <br />
                    <Paragraph>
                        <Text className="text-small">{t('packagePicker.hint.approxTimes')}</Text>
                    </Paragraph>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default React.memo(PackagePicker);
