import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Checkbox, Col, Row, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';

interface IPackagePrivacyProps {
    privacyTypeId?: number;
    upgradeChecked: boolean;
    onCheckChanged: (checked: boolean) => void;
}

const SummaryPrivacy: React.FunctionComponent<IPackagePrivacyProps> = (props) => {
    const { t } = useTranslation();

    const informationIcon = `/static/information.png`;

    const infoClicked = useCallback(() => {
        const modal = Modal.info({
            icon: undefined,
            maskClosable: true,
            okText: t('packagePicker.labels.close'),
            autoFocusButton: null,
            okType: 'default',
            centered: true,
            title: t('packagePicker.titles.privateSessions'),
            className: 'player-container',
            content: (
                <Paragraph>
                    {t('packagePicker.hint.privateSessions')
                        .split('\n')
                        .map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </Paragraph>
            ),
            onOk: () => {
                modal.destroy();
            },
            onCancel: () => {
                modal.destroy();
            },
        });
    }, []);

    if (!props.privacyTypeId || props.privacyTypeId === 0 || props.privacyTypeId === 1) return null;

    return (
        <Row justify="start" align="top">
            <Col
                onClick={() => {
                    const upgradeChecked = props.upgradeChecked !== true;
                    props.onCheckChanged(upgradeChecked);
                }}
            >
                <Checkbox
                    className="upgrade-checkbox"
                    checked={props.upgradeChecked}
                    onChange={(e) => props.onCheckChanged(e.target.checked)}
                />
                <Text className="upgrade-text-small">{t('bookingSummary.labels.upgradeToPrivateSession')}</Text>
            </Col>
            <Col>
                <img src={informationIcon} className="scarcity-image" onClick={infoClicked} />
            </Col>
        </Row>
    );
};

export default React.memo(SummaryPrivacy);
