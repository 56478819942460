import React, { useCallback } from 'react';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import SessionTime from '../../interfaces/ISessionTime';
import ZLButton, { ButtonType } from '../zl-button/zl-button';

export interface ITimeButtonProps {
    item: SessionTime;
    selected: boolean;
    disabled: boolean;
    soldOut: boolean;
    warningText: string;
    onClick: (time: SessionTime) => void;
}

const TimeButton: React.FunctionComponent<ITimeButtonProps> = (props) => {
    const item = props.item;
    const onClickEvent = props.onClick;
    const time = moment(item.time).utc();

    let formattedTime = time.format('LT');
    let timeSuffix = <React.Fragment />;

    if (formattedTime.toLowerCase().includes('am') || formattedTime.toLowerCase().includes('pm')) {
        formattedTime = formattedTime.replace(RegExp('(am)|(pm)', 'gi'), '').trim();
        timeSuffix = <Text className="time-suffix">{time.format('A')}</Text>;
    }

    const timeClicked = useCallback(() => {
        if (onClickEvent) onClickEvent(item);
    }, [onClickEvent, item]);

    const className = props.soldOut ? 'zl-time-button sold-out' : 'zl-time-button';

    return (
        <ZLButton
            type={ButtonType.Default}
            selected={props.selected}
            disabled={props.disabled}
            className={className}
            onClick={timeClicked}
        >
            <Text className="time">
                {formattedTime}
                {timeSuffix}
            </Text>
            <Text className="status">{props.warningText}</Text>
        </ZLButton>
    );
};

export default React.memo(TimeButton);
