interface LogMessage {
    name?: string;
    message: string;
    stack?: string;
    siteKey?: string;
    paymentId: string;
}

class Logging {
    static globalSiteKey: string = '';
    static paymentId: string = '';

    static SetGlobalSiteKey(siteKey: string) {
        this.globalSiteKey = siteKey;
    }

    static SetPaymentId(paymentId?: string) {
        if (paymentId == null) return;

        this.paymentId = paymentId;
    }

    static LogError(error: Error) {
        if (!error) return null;

        let logMessage = {
            name: error.name,
            message: error.message,
            stack: error?.stack ?? '',
            siteKey: this.globalSiteKey,
            paymentId: this.paymentId,
        };
        let logMessageJson = JSON.stringify(logMessage);

        console.error(logMessageJson);

        try {
            return fetch(`log/error`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: logMessageJson,
            });
        } catch {
            // No exception
        }
    }

    static LogWarning(message: string) {
        if (!message) return null;

        let logMessage = { message: message, siteKey: this.globalSiteKey, paymentId: this.paymentId };
        let logMessageJson = JSON.stringify(logMessage);

        try {
            return fetch(`log/warning`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: logMessageJson,
            });
        } catch {
            // No exception
        }
    }

    static LogInfo(message: string) {
        if (!message) return null;

        let logMessage = { message: message, siteKey: this.globalSiteKey, paymentId: this.paymentId };
        let logMessageJson = JSON.stringify(logMessage);

        try {
            return fetch(`log/info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: logMessageJson,
            });
        } catch {
            // No exception
        }
    }
}

export default Logging;
