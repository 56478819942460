import i18next from 'i18next';

const pluralHelper = {
    getKey: (lng: string, key: string, count: number): string => {
        i18next.services.pluralResolver.options.compatibilityJSON = 'v3';

        // Reviewed the following:
        // i18next--  https://www.i18next.com/translation-function/plurals
        // To use the framework as it expects, we would need to stop using the pluralHelper as it's currently written.
        // At the moment we are only needing to account for Czech; if we wanted to account for all languages in the future this might be the way to go.

        // if language is czech, manually route to nominative vs genitive plural
        if (lng.startsWith('cs') && !key.startsWith('statuses')) {
            if (count === 1) {
                // singular
                return `${key}`;
            } else if (count >= 2 && count <= 4) {
                // nominative plural
                return `${key}_czech_nominative_plural`; // ticket_czech_nominative_plural
            } else {
                // genitive plural
                return `${key}_czech_genitive_plural`;
            }
        } else {
            const rule = i18next.services.pluralResolver.getRule(lng);
            const suffix = i18next.services.pluralResolver.getSuffix(lng, count);

            //find the 18next git and read the pluralResolver code
            //const plurals = i18next.services.pluralResolver.getPluralFormsOfKey(lng, key);
            const output = rule && rule.numbers.length === 2 && suffix === '_1' ? `${key}_plural` : `${key}`;
            return output;
        }
    },
};

export default pluralHelper;
