import React from 'react';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../store';

const VoucherImage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const expiryYears = useSelector((state: IApplicationState) => state.giftVoucher.configDetails?.expiryYears);

    let expirationDate = new Date();
    let expirationDateString = expirationDate.toDateString();

    if (expiryYears) {
        expirationDate.setFullYear(expirationDate.getFullYear() + expiryYears);
        expirationDateString = expirationDate.toDateString();
        let parts = expirationDateString.split(' ');
        expirationDateString = parts.slice(1).join(' ');
    }

    const ExpiryDate: React.FunctionComponent = () => {
        if (expiryYears) {
            return <Text>{exirationDateText}</Text>;
        } else {
            return <React.Fragment></React.Fragment>;
        }
    };

    const exirationDateText = t('giftVoucherSection.details.expirationLabel') + ': ' + expirationDateString;

    return <ExpiryDate />;
};

export default VoucherImage;
