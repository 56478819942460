import './game-dropdown.scss';
import scrollIntoView from 'scroll-into-view-if-needed';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Select } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import forEach from 'lodash/forEach';
import React, { useMemo } from 'react';
import Experience from '../../interfaces/IExperience';
// import { appInsights } from "../../Logging/appInsights";

const { Option, OptGroup } = Select;

export interface IGameDropdownProps {
    games: Experience[];
    selectedGame: Experience | undefined;
    value?: number;
    placeholder: string;
    onChange?: (value: number) => void;
}

const GameDropdown: React.FunctionComponent<IGameDropdownProps> = (props) => {
    const { t } = useTranslation();

    const scrollRef: React.RefObject<HTMLElement> = useMemo(() => {
        return React.createRef();
    }, []);

    const GameItem = (game: Experience): React.ReactElement => {
        return (
            <Option className="zl-game-option" value={game.experienceId} key={game.experienceId}>
                {game.name}
            </Option>
        );
    };

    const filter = (inputValue: string, grouping: any): boolean => {
        try {
            const searchTerm = inputValue.toLowerCase();

            if (grouping.label) return (grouping.label as string).toLowerCase().includes(searchTerm);
            if (grouping.children) return (grouping.children as string).toLowerCase().includes(searchTerm);
        } catch (error) {
            console.warn(error);
            // appInsights?.trackException(
            //     { error: new Error(`Error filtering game dropdown`), severityLevel: 2},
            //     { exception: error, sourceFile: "game-dropdown.tsx", product: "booking"  });
        }
        return false;
    };

    const items = useMemo(() => {
        const retValue: JSX.Element[] = [];
        forEach(props.games, function (game: Experience) {
            retValue.push(GameItem(game));
        });
        return retValue;
    }, [props.games]);

    const gamesDropdownChanged = (value: number, option: object[] | object) => {
        if (props.onChange) props.onChange(value);
    };

    const onDropdownOpen = (open: boolean) => {
        const node = scrollRef.current;
        if (open && node) {
            const scrollIntoViewSmoothly =
                'scrollBehavior' in document.documentElement.style ? scrollIntoView : smoothScrollIntoView;

            scrollIntoViewSmoothly(node, { behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <section ref={scrollRef}>
            <Select
                data-testid="game-dropdown"
                showSearch
                filterOption={filter}
                onSelect={gamesDropdownChanged}
                value={props.selectedGame?.experienceId ?? 0}
                className="zl-game-dropdown"
                dropdownClassName="zl-game-dropdown-menu"
                size="large"
                suffixIcon={<CaretDownFilled />}
                onDropdownVisibleChange={onDropdownOpen}
                placeholder={props.placeholder}
            >
                <Option className="zl-game-option" value={0} key={0}>
                    {t('gamePicker.control.allGames')}
                </Option>
                {items}
            </Select>
        </section>
    );
};

export default React.memo(GameDropdown);
