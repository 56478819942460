/// Copied from Global Website

export interface RichText {
    __typename: string;
    json: string;
}
export const query: string = `
__typename
json
`;
