import React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';

interface IPackageDescriptionProps {
    description?: string;
}

const PackageDescription: React.FunctionComponent<IPackageDescriptionProps> = (props) => {
    if (props.description === undefined)
        return (
            <Paragraph>
                <Text>Missing language data</Text>
            </Paragraph>
        );

    const packageDescription = props.description.split('\n').map((line, index) => (
        <Text key={index}>
            {line}
            <br />
        </Text>
    ));

    return <Paragraph>{packageDescription}</Paragraph>;
};

export default React.memo(PackageDescription);
