/// Copied from Global Website
/// Shared cookies with Global Website

import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Switch, Row, Col, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
import useCookiesMenu from '../../hooks/useCookiesMenu';
import RichTextComponent from '../rich-text-component/rich-text-component';

const { Title, Text } = Typography;

export enum CookieNames {
    Performance = 'PerformanceCookies',
    Functional = 'FunctionalCookies',
    Marketing = 'MarketingCookies',
    IsCookieSaved = 'IsCookieSaved',
}

export interface CookieStates {
    PerformanceCookies: boolean;
    FunctionalCookies: boolean;
    MarketingCookies: boolean;
}

const Cookie = () => {
    const [open, setOpen] = useState<boolean>(true);
    const [settings, setSettings] = useState<boolean>(false);

    const YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;

    const [cookieStates, setCookieStates] = useState<CookieStates>({
        PerformanceCookies: false,
        FunctionalCookies: false,
        MarketingCookies: false,
    });

    const [cookies, setCookie] = useCookies([
        CookieNames.Performance,
        CookieNames.Functional,
        CookieNames.Marketing,
        CookieNames.IsCookieSaved,
    ]);

    const { loading, error, liveData } = useCookiesMenu();

    useEffect(() => {
        if (liveData) {
            const initialStates: CookieStates = {
                PerformanceCookies: false,
                FunctionalCookies: false,
                MarketingCookies: false,
            };
            const cookiesMenuData = liveData.cookiesMenuCollection.items[0];
            cookiesMenuData.cookieSettingsCollection.items.forEach((setting) => {
                initialStates[setting.featureToggleName as keyof CookieStates] = setting.alwaysActive;
            });
            setCookieStates(initialStates);
        }
    }, [liveData]);

    if (error) {
        return <h1>There was an error</h1>;
    }

    if (loading) {
        return <span>Loading...</span>;
    }

    if (!liveData) return <></>;

    const cookiesMenuData = liveData.cookiesMenuCollection.items[0];

    const updateCookie = (name: CookieNames, value: boolean) => {
        setCookie(name, value, {
            path: '/',
            expires: new Date(Date.now() + YEAR_IN_MS),
            domain: '.zerolatencyvr.com',
        });
    };

    const handleCookieChange = (type: keyof CookieStates) => (checked: boolean) => {
        setCookieStates((prev) => ({ ...prev, [type]: checked }));
    };

    const handleAcceptAll = () => {
        const allTrue = {
            PerformanceCookies: true,
            FunctionalCookies: true,
            MarketingCookies: true,
        };
        setCookieStates(allTrue);
        Object.keys(allTrue).forEach((key) => updateCookie(key as CookieNames, true));
        setCookie(CookieNames.IsCookieSaved, true, {
            path: '/',
            expires: new Date(Date.now() + YEAR_IN_MS),
            domain: '.zerolatencyvr.com',
        });
        setOpen(false);
    };

    const handleRejectAll = () => {
        const allFalse = {
            PerformanceCookies: false,
            FunctionalCookies: false,
            MarketingCookies: false,
        };
        setCookieStates(allFalse);
        Object.keys(allFalse).forEach((key) => updateCookie(key as CookieNames, false));
        setCookie(CookieNames.IsCookieSaved, true, {
            path: '/',
            expires: new Date(Date.now() + YEAR_IN_MS),
            domain: '.zerolatencyvr.com',
        });
        setOpen(false);
    };

    const handleClose = () => {
        Object.entries(cookieStates).forEach(([key, value]) => {
            updateCookie(key as CookieNames, value);
        });
        setCookie(CookieNames.IsCookieSaved, true, {
            path: '/',
            expires: new Date(Date.now() + YEAR_IN_MS),
            domain: '.zerolatencyvr.com',
        });
        setOpen(false);
    };

    const handleOpenSettings = () => {
        setSettings(true);
    };

    const handleCloseSettings = () => {
        setSettings(false);
    };

    const heading = settings ? cookiesMenuData.settingHeading : cookiesMenuData.policyHeading;
    const descriptionJson = settings ? cookiesMenuData.settingBody.json : cookiesMenuData.policyBody.json;

    if (cookies.IsCookieSaved) return <></>;

    const SettingDetailComponent = () => (
        <Row gutter={[0, 16]} style={{ marginTop: 16 }}>
            <Col span={24}>
                <Title level={5} style={{ color: '#00202C', marginBottom: 0 }}>
                    {cookiesMenuData.managePreferencesHeading}
                </Title>
            </Col>
            {cookiesMenuData.cookieSettingsCollection.items.map((component, index) => (
                <React.Fragment key={index}>
                    <Col span={24}>
                        <Space
                            style={{
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Text strong style={{ color: '#00202C' }}>
                                {component.heading}
                            </Text>
                            {component.alwaysActive ? (
                                <Text strong style={{ color: '#00202C' }}></Text>
                            ) : (
                                <Switch
                                    checked={cookieStates[component.featureToggleName as keyof CookieStates]}
                                    onChange={handleCookieChange(component.featureToggleName as keyof CookieStates)}
                                />
                            )}
                        </Space>
                        <Text style={{ color: '#00202C' }}>{component.description}</Text>
                    </Col>
                </React.Fragment>
            ))}
        </Row>
    );

    return (
        <Modal
            visible={open}
            closable={false}
            footer={null}
            bodyStyle={{ backgroundColor: '#F5F5F5' }}
            onCancel={() => handleClose()}
        >
            {settings && (
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={handleCloseSettings}
                    style={{
                        position: 'absolute',
                        width: '48px',
                        right: 0,
                        top: 0,
                        color: '#00202C',
                    }}
                />
            )}
            <div style={{ position: 'relative' }}>
                <Title level={3} style={{ color: '#00202C', marginBottom: 8 }}>
                    {heading}
                </Title>
            </div>

            <div style={{ marginBottom: 16 }}>
                <RichTextComponent bodyType="body1" jsonData={descriptionJson} />
            </div>

            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAcceptAll} style={{ padding: '6px 20px' }}>
                    {cookiesMenuData.acceptAllButtonText}
                </Button>
                <Button onClick={handleRejectAll} style={{ padding: '6px 20px' }}>
                    {cookiesMenuData.rejectAllButtonText}
                </Button>
            </Space>

            {!settings && (
                <div style={{ marginTop: 16 }}>
                    <a onClick={handleOpenSettings} style={{ cursor: 'pointer' }}>
                        <Text strong style={{ color: '#00202C' }}>
                            {cookiesMenuData.manageButtonText}
                        </Text>
                    </a>
                </div>
            )}

            {settings && <SettingDetailComponent />}

            {settings && (
                <Row style={{ marginTop: 16 }}>
                    <Col span={12}>
                        <Button onClick={handleClose} style={{ padding: '6px 20px' }}>
                            {cookiesMenuData.confirmChoicesButtonText}
                        </Button>
                    </Col>
                </Row>
            )}
        </Modal>
    );
};

Cookie.displayName = 'Cookie';
export default Cookie;
