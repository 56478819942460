/// Copied from Global Website

import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'antd';
import React from 'react';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsonData: any;
    // Replacing sx with a style prop (an object of CSSProperties)
    style?: React.CSSProperties;
    bodyType?: 'body1' | 'body2'; // This prop is no longer used for variant selection in antd
}

const RichTextComponent: React.FC<Props> = ({ jsonData, style, bodyType = 'body1' }) => {
    if (jsonData === null || jsonData === undefined) return null;

    const processTextNode = (text: string) => {
        const parts = text.split('\n');
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < parts.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    const options: Options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => (
                <Typography.Paragraph style={{ marginBottom: 24, ...style }}>{children}</Typography.Paragraph>
            ),
            [BLOCKS.OL_LIST]: (_node, children) => <ol style={{ marginBottom: 24, ...style }}>{children}</ol>,
            [BLOCKS.UL_LIST]: (_node, children) => <ul style={{ marginBottom: 24, ...style }}>{children}</ul>,
            [BLOCKS.LIST_ITEM]: (_node, children) => <li style={{ marginBottom: 8, ...style }}>{children}</li>,
            [BLOCKS.HEADING_1]: (_node, children) => (
                <Typography.Title level={1} style={{ marginBottom: 16, ...style }}>
                    {children}
                </Typography.Title>
            ),
            [BLOCKS.HEADING_2]: (_node, children) => (
                <Typography.Title level={2} style={{ marginBottom: 16, ...style }}>
                    {children}
                </Typography.Title>
            ),
            [BLOCKS.HEADING_3]: (_node, children) => (
                <Typography.Title level={3} style={{ marginBottom: 16, ...style }}>
                    {children}
                </Typography.Title>
            ),
            [BLOCKS.HEADING_4]: (_node, children) => (
                <Typography.Title level={4} style={{ marginBottom: 16, ...style }}>
                    {children}
                </Typography.Title>
            ),
            [BLOCKS.HEADING_5]: (_node, children) => (
                <Typography.Title level={5} style={{ marginBottom: 16, ...style }}>
                    {children}
                </Typography.Title>
            ),
            [BLOCKS.HEADING_6]: (_node, children) => (
                // Ant Design does not have a direct equivalent for h6; using an h6 element directly
                <h6 style={{ marginBottom: 16, ...style }}>{children}</h6>
            ),
        },
        renderText: (text: string) => processTextNode(text),
    };

    return <>{documentToReactComponents(jsonData, options)}</>;
};

RichTextComponent.displayName = 'RichTextComponent';
export default RichTextComponent;
