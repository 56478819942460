import './processing.scss';

import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState } from '../../store';

import Heading from '../../components/heading/heading';

import { actionCreators as PaymentActions, PaymentMethodTypes } from '../../store/Payments';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import useQuery from '../../hooks/useQuery';
import { push } from 'connected-react-router';
import { actionCreators as BookingActions } from '../../store/Booking';
import { actionCreators as GiftVoucherActions } from '../../store/GiftVoucher';
import adBlockChecker from '../../helpers/adBlockChecker';
import { PurchaseTypes } from '../../store/PurchaseTypes';
// import { appInsights } from "../../Logging/appInsights";

const ProcessingPage: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const query = useQuery();
    const backProcessing = useSelector((state: IApplicationState) => state.payments.backProcessing);
    const selectedSite = useSelector((state: IApplicationState) => state.sites.selectedSite);
    const clientSecret = useSelector((state: IApplicationState) => state.payments.clientSecret);

    const selectedPurchaseType = useSelector((state: IApplicationState) => state.purchaseType.selectedPurchaseType);
    const currentMoment = useSelector((state: IApplicationState) => state.intl.momentLocale);

    let adBlockDetected = false;
    adBlockChecker.IsAdBlockEnabled().then((flag) => {
        adBlockDetected = flag;
    });

    useEffect(() => {
        const redirectStatus = query.get('redirect_status');
        const paymentIntent = query.get('payment_intent');
        const paymentIntentClientSecret = query.get('payment_intent_client_secret');

        if (paymentIntent && paymentIntentClientSecret && redirectStatus) {
            console.log('redirectStatus: ' + redirectStatus);
            if (redirectStatus === 'failed') {
                console.log('redirect status is FAILED, push user to checkout');
                dispatch(push(`/${currentMoment}/checkout/${selectedSite?.urlKey}`));
            } else if (redirectStatus === 'succeeded') {
                console.log('redirect status is SUCCEEDED, awaiting confirmation');
                if (Number(process.env.REACT_APP_CONFIRM_PAYMENTS) === 1) {
                    if (
                        selectedPurchaseType === PurchaseTypes.Booking ||
                        selectedPurchaseType === PurchaseTypes.PrivateEvent
                    ) {
                        console.log('attempt confirm booking or private event');
                        dispatch(
                            BookingActions.confirmBooking(
                                { StripeChargeId: paymentIntent },
                                paymentIntent,
                                Number(PaymentMethodTypes.Stripe),
                                adBlockDetected
                            )
                        );
                    } else if (selectedPurchaseType === PurchaseTypes.GiftVoucher) {
                        console.log('attempt confirm gift voucher');
                        dispatch(
                            GiftVoucherActions.confirmGiftVoucher(
                                { StripeChargeId: paymentIntent },
                                paymentIntent,
                                Number(PaymentMethodTypes.Stripe),
                                adBlockDetected
                            )
                        );
                    } else {
                        let errMsg = 'No Purchase type was selected when trying to confirm purchase';
                        console.error(errMsg);
                        // appInsights?.trackException(
                        //     { error: new Error(`Error processing payment : ${errMsg}`), severityLevel: 2},
                        //     { sourceFile: "processing.tsx", product: "booking"  });
                    }
                }
            }
        }
    }, [dispatch, query, clientSecret]);

    useEffect(() => {
        if (!backProcessing) {
            dispatch(PaymentActions.backProcessingAdded());

            // Clone location object and push it to history
            history.push({
                pathname: location.pathname,
                search: location.search,
            });
        }
    }, []);

    useEffect(() => {
        return history.listen((newLocation, action) => {
            // listen
            if (action === 'POP') {
                console.log('action is POP');
                history.go(1);
            }
        });
    }, [history]);

    return (
        <React.Fragment>
            <Row justify="center" align="middle" className="processing-container">
                <Col className="text-center">
                    <Heading level={1} className="massive">
                        <LoadingSpinner />
                    </Heading>
                    <Heading level={2} className="massive">
                        {t('pageTitles.processingBooking')}
                    </Heading>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProcessingPage;
