import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { Action, Reducer } from 'redux';
import { Dictionary } from 'typescript-collections';

import { IApplicationState, IAnalyticsAction, IAppThunkAction } from '.';
import OpenDate from '../interfaces/IOpenDate';
import Session from '../interfaces/ISession';
import SessionTime from '../interfaces/ISessionTime';
import { ICharge } from '../interfaces/ICharge';
import OpenDatesService from '../service/opendates';
import SessionsService from '../service/sessions';

import { max, maxBy, minBy } from 'lodash';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';
import PackageTierPrice from '../interfaces/IPackageTierPrice';
import { PurchaseTypes } from './PurchaseTypes';
import { PLAYERS_SELECTED, IPlayersSelectedAction } from './Players';
import { GAME_SELECTED, IGameSelectedAction } from './Games';
// import { appInsights } from "../Logging/appInsights";

export interface ISessionState {
    openDates?: Dictionary<string, OpenDate>;
    openDatesLoading: boolean;
    openDatesLoaded: boolean;
    sessionTimesLoading: boolean;
    sessionTimesLoaded: boolean;
    sessionTimes?: Array<SessionTime>;
    sessions?: Dictionary<string, Session[]>;
    selectedDate?: string;
    selectedDateUI: boolean;
    selectedTime?: SessionTime;
    selectedTimeUI: boolean;
    selectedSession?: Session;
    selectedSessionUI: boolean;
    charge?: ICharge;
}

export const DATE_SELECTED: 'DATE_SELECTED' = 'DATE_SELECTED';
export const OPEN_DATES_LOADING: 'OPEN_DATES_LOADING' = 'OPEN_DATES_LOADING';
export const OPEN_DATES_LOADED: 'OPEN_DATES_LOADED' = 'OPEN_DATES_LOADED';
export const VIEWED_PACKAGE_DETAILS: 'VIEWED_PACKAGE_DETAILS' = 'VIEWED_PACKAGE_DETAILS';
export const VIEWED_PACKAGE_VIDEO: 'VIEWED_PACKAGE_VIDEO' = 'VIEWED_PACKAGE_VIDEO';
export const SESSION_TIMES_LOADING: 'SESSION_TIMES_LOADING' = 'SESSION_TIMES_LOADING';
export const SESSION_TIMES_LOADED: 'SESSION_TIMES_LOADED' = 'SESSION_TIMES_LOADED';
export const SESSION_TIME_SELECTED: 'SESSION_TIME_SELECTED' = 'SESSION_TIME_SELECTED';
export const SESSION_SELECTED: 'SESSION_SELECTED' = 'SESSION_SELECTED';
export const CLEAR_TIME_SELECTION: 'CLEAR_TIME_SELECTION' = 'CLEAR_TIME_SELECTION';
export const CLEAR_DATE_TIME_SELECTION: 'CLEAR_DATE_TIME_SELECTION' = 'CLEAR_DATE_TIME_SELECTION';

export interface ILoadOpenDatesAction extends Action {
    type: typeof OPEN_DATES_LOADING;
}

export interface IOpenDatesLoadedAction extends Action {
    type: typeof OPEN_DATES_LOADED;
    openDates?: Dictionary<string, OpenDate>;
}

export interface IPackageDetailsViewedAction extends IAnalyticsAction {
    type: typeof VIEWED_PACKAGE_DETAILS;
    session: Session;
}

export interface IPackageVideoViewedAction extends IAnalyticsAction {
    type: typeof VIEWED_PACKAGE_VIDEO;
    session: Session;
}

export interface IDateSelectedAction extends IAnalyticsAction {
    type: typeof DATE_SELECTED;
    date: string;
}

export interface ISessionTimesLoadingAction extends Action {
    type: typeof SESSION_TIMES_LOADING;
}

export interface ISessionTimesLoadedAction extends Action {
    type: typeof SESSION_TIMES_LOADED;
    sessions?: Dictionary<string, Session[]>;
    sessionTimes?: Array<SessionTime>;
}

export interface ISessionTimeSelectedAction extends IAnalyticsAction {
    type: typeof SESSION_TIME_SELECTED;
    time: SessionTime;
}

export interface ISessionSelectedAction extends IAnalyticsAction {
    type: typeof SESSION_SELECTED;
    session: Session;
}

export interface IClearTimeSelection extends Action {
    type: typeof CLEAR_TIME_SELECTION;
}

export interface IClearDateTimeSelection extends Action {
    type: typeof CLEAR_DATE_TIME_SELECTION;
}

export type KnownSessionActions =
    | ILoadOpenDatesAction
    | IOpenDatesLoadedAction
    | IPackageDetailsViewedAction
    | IPackageVideoViewedAction
    | IDateSelectedAction
    | ISessionTimesLoadingAction
    | ISessionTimesLoadedAction
    | ISessionTimeSelectedAction
    | ISessionSelectedAction
    | IClearTimeSelection
    | IClearDateTimeSelection
    | IPlayersSelectedAction
    | IGameSelectedAction;

export const actionCreators = {
    clearSelectedDateTime: () => ({ type: CLEAR_DATE_TIME_SELECTION } as IClearDateTimeSelection),

    loadOpenDates:
        (
            siteId: number,
            start: string,
            end: string,
            accessCode: string | undefined,
            experienceId: number | undefined,
            packageTypeId: number | undefined
        ): IAppThunkAction<KnownSessionActions> =>
        (dispatch, getState) => {
            OpenDatesService.Search(
                siteId,
                start,
                end,
                accessCode,
                packageTypeId === 1 ? experienceId : undefined,
                packageTypeId
            )
                .then((data) => {
                    const dates: Dictionary<string, OpenDate> = new Dictionary<string, OpenDate>();

                    data.forEach((openDate: OpenDate) => {
                        //enforce en date locale for data lookup.
                        dates.setValue(moment(openDate.date).utc().locale('en').format('YYYY-MM-DD'), {
                            siteId: openDate.siteId,
                            date: openDate.date,
                            status: openDate.status,
                            bookedSlots: openDate.bookedSlots,
                            totalSlots: openDate.totalSlots,
                            pendingSlots: openDate.pendingSlots,
                            remainingSlots: openDate.remainingSlots,
                        });
                    });

                    dispatch({ type: OPEN_DATES_LOADED, openDates: dates });
                })
                .catch((error: Error) => {
                    dispatch({ type: OPEN_DATES_LOADED, openDates: new Dictionary<string, OpenDate>() });
                    // appInsights?.trackException(
                    //     { error: new Error(`Error loading open dates`), severityLevel: 3},
                    //     { exception: error, sourceFile: "Sessions.ts", product: "booking" });
                });
            dispatch({ type: OPEN_DATES_LOADING });
        },
    loadSessionTimes:
        (date: moment.Moment, experienceId: number | undefined): IAppThunkAction<KnownSessionActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();
            const site = appState.sites.selectedSite;
            const accessCode = appState.sites.accessCode;
            const players = appState.players.selectedPlayers;
            const selectedPurchaseType = appState.purchaseType.selectedPurchaseType;
            const priceSet = appState.booking.priceSets?.find((p) => p.venue === site?.urlKey);

            const packageTypeId = selectedPurchaseType == PurchaseTypes.Booking ? 1 : 2;

            if (appState && site && appState.players.selectedPlayers && appState.session.selectedDate) {
                SessionsService.GetSessionTimes(
                    site,
                    date,
                    players,
                    accessCode,
                    experienceId,
                    packageTypeId,
                    priceSet?.code
                )
                    .then((data) => {
                        const sessions: Dictionary<string, Session[]> = new Dictionary<string, Session[]>();
                        const groupedSessions = groupBy(data, (session: Session) => session.startTime);

                        //both sessions and session times in one map
                        const sessionTimes: Array<SessionTime> = map(
                            groupedSessions,
                            (value: Session[], key: string) => {
                                sessions.setValue(key, value);

                                return {
                                    time: key,
                                    openTimeMinimumPlayers: minBy<number>(
                                        value.map((s) => (s.bookedSlots > 0 ? 1 : s.openTimeMinimumPlayers)),
                                        (openTimeMinimumPlayers: number) => {
                                            return openTimeMinimumPlayers;
                                        }
                                    ),
                                    slotsRemaining: maxBy(
                                        value.map((s) => (s.isPrivate ? 0 : s.remainingSlots)),
                                        (remainingSlots: number) => {
                                            return remainingSlots;
                                        }
                                    ),
                                } as SessionTime;
                            }
                        );

                        dispatch({ type: SESSION_TIMES_LOADED, sessions: sessions, sessionTimes: sessionTimes });
                    })
                    .catch((error: Error) => {
                        dispatch({ type: SESSION_TIMES_LOADED, sessions: undefined, sessionTimes: undefined });
                        // appInsights?.trackException(
                        //     { error: new Error(`Error loading session times`), severityLevel: 4},
                        //     { exception: error, sourceFile: "Sessions.ts", product: "booking" });
                    });
                dispatch({ type: SESSION_TIMES_LOADING });
            }
        },

    postPackageVideoViewedAction:
        (session: Session): IAppThunkAction<KnownSessionActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();

            const siteCode = appState.sites.selectedSite?.code ?? ('' as string);
            const appliedCodes = appState.booking.appliedCodes;

            const ticketCount = appState.players.selectedPlayers ?? (0 as number);

            dispatch({
                type: VIEWED_PACKAGE_VIDEO,
                session: session,
                googleTagManagerEvent: GoogleTagManagerEvents.ViewedPackageVideo(
                    session,
                    siteCode,
                    ticketCount,
                    appliedCodes
                ),
            } as IPackageVideoViewedAction);
        },

    postPackageDetailsViewedAction:
        (session: Session): IAppThunkAction<KnownSessionActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();

            const siteCode = appState.sites.selectedSite?.code ?? ('' as string);
            const appliedCodes = appState.booking.appliedCodes;

            const ticketCount = appState.players.selectedPlayers ?? (0 as number);

            dispatch({
                type: VIEWED_PACKAGE_DETAILS,
                session: session,
                googleTagManagerEvent: GoogleTagManagerEvents.ViewedPackageDetails(
                    session,
                    siteCode,
                    ticketCount,
                    appliedCodes
                ),
            } as IPackageDetailsViewedAction);
        },

    postDateSelectedAction: (date: string) =>
        ({
            type: DATE_SELECTED,
            date: date,
            googleTagManagerEvent: GoogleTagManagerEvents.SessionDateSelected(date),
        } as IDateSelectedAction),

    postTimeSelectedAction: (time: SessionTime) =>
        ({
            type: SESSION_TIME_SELECTED,
            time: time,
            googleTagManagerEvent: GoogleTagManagerEvents.SessionTimeSelected(time),
        } as ISessionTimeSelectedAction),

    clearSelectedTime: () => ({ type: CLEAR_TIME_SELECTION }),
    postSessionSelectedAction:
        (session: Session): IAppThunkAction<KnownSessionActions> =>
        (dispatch, getState) => {
            const appState: IApplicationState = getState();

            const siteCode = appState.sites.selectedSite?.code ?? '';

            // Calculate the costs here
            const priceTierTypeId = session?.priceTierTypeId;
            const packageTierPrice = session?.package.packageTierPrices.find(
                (ptp: PackageTierPrice) => ptp.priceTierType.priceTierTypeId === priceTierTypeId
            );
            const pricing = packageTierPrice?.price;
            //const currencyLocale = pricing?.currencyLocale;
            const currencyCode = pricing?.currencyCode ?? '';
            //const taxInclusive = pricing?.taxInclusive;
            const ticketCount = appState.players.selectedPlayers ?? 0;

            let tax = pricing?.tax ?? 0;
            const ticketCost = pricing?.total ?? 0;
            let total = pricing?.total ?? 0;

            //a package has been selected but there is no charge object from the server.
            //pricing from packages are based on 1 ticket, so the tax and total needs to be for all tickets
            if (pricing !== undefined && ticketCount !== undefined && ticketCount > 1) {
                tax *= ticketCount;
                total *= ticketCount;
            }

            dispatch({
                type: SESSION_SELECTED,
                session: session,
                googleTagManagerEvent: GoogleTagManagerEvents.SessionPackageSelected(session),
            } as ISessionSelectedAction);
        },
};

const unloadedState: ISessionState = {
    openDatesLoaded: false,
    openDates: undefined,
    openDatesLoading: false,
    sessions: undefined,
    sessionTimes: undefined,
    selectedDate: undefined,
    sessionTimesLoaded: false,
    sessionTimesLoading: false,
    selectedSession: undefined,
    selectedDateUI: false,
    selectedTimeUI: false,
    selectedSessionUI: false,
};

export const reducer: Reducer<ISessionState> = (
    state: ISessionState | undefined,
    incomingAction: Action
): ISessionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action: KnownSessionActions = incomingAction as KnownSessionActions;
    switch (action.type) {
        case GAME_SELECTED:
            return {
                ...state,
                selectedDate: undefined,
                selectedSession: undefined,
                selectedTime: undefined,
                selectedDateUI: false,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case PLAYERS_SELECTED:
            return {
                ...state,
                selectedDate: undefined,
                selectedSession: undefined,
                selectedTime: undefined,
                selectedDateUI: false,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case CLEAR_TIME_SELECTION:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                openDates: state.openDates,
                sessions: state.sessions,
                sessionTimes: state.sessionTimes,
                selectedDate: state.selectedDate,
                sessionTimesLoaded: true,
                sessionTimesLoading: false,
                selectedSession: undefined,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case CLEAR_DATE_TIME_SELECTION:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                openDates: state.openDates,
                sessions: state.sessions,
                sessionTimes: state.sessionTimes,
                selectedDate: undefined,
                sessionTimesLoaded: true,
                sessionTimesLoading: false,
                selectedSession: undefined,
                selectedDateUI: false,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case OPEN_DATES_LOADING:
            return {
                openDatesLoaded: false,
                openDatesLoading: true,
                openDates: undefined,
                sessions: undefined,
                sessionTimes: undefined,
                selectedDate: undefined,
                sessionTimesLoading: false,
                sessionTimesLoaded: false,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: state.selectedTimeUI,
                selectedSessionUI: state.selectedSessionUI,
            };
        case OPEN_DATES_LOADED:
            return {
                openDatesLoaded: true,
                openDatesLoading: false,
                openDates: action.openDates,
                sessions: undefined,
                sessionTimes: undefined,
                selectedDate: undefined,
                sessionTimesLoading: false,
                sessionTimesLoaded: false,
                selectedDateUI: false,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case DATE_SELECTED:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                openDates: state.openDates,
                sessions: undefined,
                sessionTimes: undefined,
                selectedDate: action.date,
                sessionTimesLoading: false,
                sessionTimesLoaded: false,
                selectedDateUI: true,
                selectedTimeUI: false,
                selectedSessionUI: false,
            };
        case SESSION_TIMES_LOADING:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                sessionTimesLoading: true,
                sessionTimesLoaded: false,
                selectedDate: state.selectedDate,
                openDates: state.openDates,
                sessionTimes: undefined,
                sessions: undefined,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: state.selectedTimeUI,
                selectedSessionUI: state.selectedSessionUI,
            };
        case SESSION_TIMES_LOADED:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                sessionTimesLoading: false,
                sessionTimesLoaded: true,
                selectedDate: state.selectedDate,
                openDates: state.openDates,
                sessionTimes: action.sessionTimes,
                sessions: action.sessions,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: state.selectedTimeUI,
                selectedSessionUI: false,
                selectedSession: undefined,
            };
        case SESSION_TIME_SELECTED:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                sessionTimesLoading: state.sessionTimesLoading,
                sessionTimesLoaded: state.sessionTimesLoaded,
                selectedDate: state.selectedDate,
                openDates: state.openDates,
                sessionTimes: state.sessionTimes,
                sessions: state.sessions,
                selectedTime: action.time,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: true,
                selectedSessionUI: false,
                selectedSession: undefined,
            };
        case SESSION_SELECTED:
            return {
                openDatesLoaded: state.openDatesLoaded,
                openDatesLoading: state.openDatesLoading,
                sessionTimesLoading: state.sessionTimesLoading,
                sessionTimesLoaded: state.sessionTimesLoaded,
                selectedDate: state.selectedDate,
                openDates: state.openDates,
                sessionTimes: state.sessionTimes,
                sessions: state.sessions,
                selectedTime: state.selectedTime,
                selectedSession: action.session,
                selectedDateUI: state.selectedDateUI,
                selectedTimeUI: state.selectedTimeUI,
                selectedSessionUI: true,
            };
        default:
            return state;
    }
};
