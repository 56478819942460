import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
//import { appInsights } from "../utilities/appInsights.tsx";

function createApolloClient(previewMode: boolean) {
    const publishedToken = 'a_r9Ql6zx9cGave6Q3-X-_ClzsKK7qkrcWynKppeP_w';
    const previewToken = '9rCU5giUtjE4EMvd2N9-6kvPHE77aHdV4HsawjuVxSE';

    const httpLink = new HttpLink({
        uri: `https://graphql.contentful.com/content/v1/spaces/adsfyhieuvzo`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${previewMode ? previewToken : publishedToken}`,
        },
    });

    const errorLink = onError(({ response, graphQLErrors, networkError }) => {
        if (response?.errors) {
            console.error(`[Apollo error]: ${response?.errors}`);
            //appInsights?.trackException({ error: new Error(`Apollo error`), severityLevel: 5 }, { url: window.location.href, error: response?.errors });
        }

        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                //appInsights?.trackException({ error: new Error(`GraphQL error`), severityLevel: 5 }, { url: window.location.href, locations: locations, path: path, error: message });
            });
        }

        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            //appInsights?.trackException({ error: new Error(`Network error`), severityLevel: 5 }, { url: window.location.href, error: networkError });
        }
    });

    const client = new ApolloClient({
        link: from([errorLink, httpLink]),
        cache: new InMemoryCache(),
        /*        connectToDevTools: process.env.NODE_ENV === 'development',*/
    });

    return client;
}

// Wrapper to use the client
export const useApolloClient = () => {
    return createApolloClient(false);
};
