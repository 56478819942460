/// Copied from Global Website
/// Shared cookies with Global Website

import { gql, useQuery } from '@apollo/client';
/*import { useContentfulLiveUpdates } from "@contentful/live-preview/react";*/
import * as CookiesCM from '../contentModels/CookieCM';

interface CookieMenuCollection {
    items: CookiesCM.CookieMenu[];
}

interface GetCookieMenuResponse {
    cookiesMenuCollection: CookieMenuCollection;
}

const useCookiesMenu = () => {
    const query: string = `
    query getCookiesMenu {
      cookiesMenuCollection(
          limit: 10,

          ){
        items{
            ${CookiesCM.Cookie_query}
        }
      }
    }
    `;

    const COOKIESMENU = gql(query);

    const { loading, error, data } = useQuery<GetCookieMenuResponse>(COOKIESMENU);
    console.log(`use cookies menu fetching data with error:${error}, and loading:${loading}`);
    //pass data thru the live preview hook to check for live updates
    //const liveData = useContentfulLiveUpdates(data);
    const liveData = data;

    return { loading, error, liveData };
};

export default useCookiesMenu;
