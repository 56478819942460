import { Action, Reducer } from 'redux';
import { IAnalyticsAction, IAppThunkAction } from '.';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';

export interface IUIState {
    errorKey?: string;
    errorMessage?: string;
}

export const ERROR_MESSAGE: 'ERROR_MESSAGE' = 'ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE' = 'CLEAR_ERROR_MESSAGE';
export const OUTBOUND_LINK: 'OUTBOUND_LINK' = 'OUTBOUND_LINK';

export const actionCreators = {
    displayError:
        (errorKey: string, additionalMessage?: string): IAppThunkAction<KnownUIActions> =>
        (dispatch, getState) => {
            dispatch({ type: ERROR_MESSAGE, error: errorKey, message: additionalMessage });
        },
    clearError: (): IAppThunkAction<KnownUIActions> => (dispatch) => {
        dispatch({ type: CLEAR_ERROR_MESSAGE });
    },
    outboundLink: (outboundUrl: string) =>
        ({
            type: OUTBOUND_LINK,
            outboundUrl: outboundUrl,
            googleTagManagerEvent: GoogleTagManagerEvents.ClickedOutboundLink(outboundUrl),
        } as IOutboundLinkAction),
};

const unloadedState: IUIState = {
    errorKey: undefined,
    errorMessage: undefined,
};

export interface IErrorMessageReceived extends Action {
    type: typeof ERROR_MESSAGE;
    error: string;
    message?: string;
}

export interface IClearErrorMessageAction extends Action {
    type: typeof CLEAR_ERROR_MESSAGE;
}

export interface IOutboundLinkAction extends IAnalyticsAction {
    type: typeof OUTBOUND_LINK;
    outboundUrl: string;
}

export type KnownUIActions = IErrorMessageReceived | IClearErrorMessageAction;

export const reducer: Reducer<IUIState> = (state: IUIState | undefined, incomingAction: Action): IUIState => {
    if (state === undefined) return unloadedState;

    const action: KnownUIActions = incomingAction as KnownUIActions;
    switch (action.type) {
        case ERROR_MESSAGE:
            return {
                errorKey: action.error,
                errorMessage: action.message,
            };
        case CLEAR_ERROR_MESSAGE:
            return {
                errorKey: undefined,
                errorMessage: undefined,
            };
        default:
            return {
                errorKey: state.errorKey,
            };
    }
};
