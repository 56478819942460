import { Middleware, MiddlewareAPI, Dispatch, Action } from 'redux';
import GoogleTagManager from '../../helpers/googleTagManager';

import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { IAnalyticsAction, IApplicationState } from '..';
import PackageTierPrice from '../../interfaces/IPackageTierPrice';
import regionConverter from '../../helpers/regionConverter';
import { PurchaseTypes } from '../PurchaseTypes';

export default function AnalyticsLogger() {
    const analyticsMiddleware: Middleware =
        ({ getState }: MiddlewareAPI) =>
        (next: Dispatch) =>
        (action: Action) => {
            // Call the next dispatch method in the middleware chain.
            const returnValue = next(action);
            const state: IApplicationState = getState();
            const knownAction: IAnalyticsAction = action as IAnalyticsAction;

            const siteCode: string | undefined = state.sites.selectedSite?.code;

            let cart = undefined;
            let purchaseState = undefined;

            const region = regionConverter.regionIdToString(state.sites.selectedSite?.regionId as number);

            const selectedPurchaseType = state.purchaseType.selectedPurchaseType;

            const priceCode = state.booking.priceSets?.find(
                (ps) => ps.venue === state.sites.selectedSite?.urlKey
            )?.code;

            if (selectedPurchaseType === PurchaseTypes.Booking || selectedPurchaseType === PurchaseTypes.PrivateEvent) {
                if (state.session.selectedSession) {
                    const charge = state.booking.charge;
                    const paymentId = state.booking.charge?.relatedPaymentId;
                    const players = state.players.selectedPlayers ?? 0;
                    const feeAmount = state.sites.bookingOptions?.feeAmount;
                    const feePercent = state.sites.bookingOptions?.feePercent;

                    // Calculate the costs here
                    const selectedSession = state.session.selectedSession;
                    const selectedPrivateUpgrade = state.players.selectedPrivateUpgrade;
                    const priceTierTypeId = selectedSession?.priceTierTypeId;
                    const discountCodes = state.booking.appliedCodes;
                    const packageTierPrice = selectedSession?.package.packageTierPrices.find(
                        (ptp: PackageTierPrice) => ptp.priceTierType.priceTierTypeId === priceTierTypeId
                    );

                    const pricing = packageTierPrice?.price;
                    const currencyCode = charge?.currencyCode ?? pricing?.currencyCode ?? '';
                    const quantity = charge?.quantity ?? players ?? 1;

                    let tax = charge?.tax ?? pricing?.tax ?? 0;
                    let fee = charge?.fee ?? 0;
                    let cost = charge?.cost ?? pricing?.cost ?? 0;
                    const ticketCost = charge?.total ?? pricing?.total ?? 0;
                    let total = ticketCost;

                    if (selectedSession.package.perPersonPricing) {
                        cost *= quantity;
                    }

                    const missingPlayers = selectedSession ? selectedSession.maximumSlots - (players ?? 8) : 0;
                    const privateSessionCost = selectedSession
                        ? (missingPlayers * (pricing?.cost ?? 0) * selectedSession.package.privacyChargePercentage) /
                          100
                        : 0;
                    const privateSessionTax = selectedSession
                        ? (missingPlayers * (pricing?.tax ?? 0) * selectedSession.package.privacyChargePercentage) / 100
                        : 0;

                    //a package has been selected but there is no charge object from the server.
                    //pricing from packages are based on 1 ticket, so the tax and total needs to be for all tickets
                    if (charge === undefined && pricing !== undefined && players !== undefined && players > 1) {
                        if (selectedSession.package.perPersonPricing) {
                            tax *= players;
                            total *= players;
                        }

                        if (selectedSession.package.privacyTypeId === 2 && selectedPrivateUpgrade) {
                            tax += privateSessionTax;
                            total += privateSessionCost + privateSessionTax;
                        }
                    }

                    if (
                        feeAmount !== undefined &&
                        feePercent !== undefined &&
                        pricing !== undefined &&
                        charge === undefined
                    ) {
                        fee = feeAmount + ((total + feeAmount) * (1 / (1 - feePercent / 100)) - (total + feeAmount));
                        total += fee;
                    }

                    let discountTotal = 0;
                    let discountCode = undefined;
                    discountCodes.forEach((discount) => {
                        if (discount.isPromoCode) {
                            discountCode = discount.code;
                            discountTotal += discount.amount;
                            //total -= discount.amount;
                        }
                    });

                    cart = {
                        transaction_id: paymentId,
                        affiliation: siteCode,
                        value: Math.round((total + Number.EPSILON) * 100) / 100,
                        currency: currencyCode,
                        tax: Math.round((tax + Number.EPSILON) * 100) / 100,
                        coupon: discountCode,
                        discount: discountTotal,
                        fee: fee,
                        items: [
                            {
                                item_id: selectedSession.package.header,
                                item_name: selectedSession.package.alias,
                                affiliation: siteCode,
                                currency: currencyCode,
                                coupon: discountCode,
                                discount: Math.round((discountTotal / players + Number.EPSILON) * 100) / 100,
                                item_brand: 'Zero Latency',
                                item_category: 'Tickets',
                                quantity: players,
                                price: Math.round((cost / players + Number.EPSILON) * 100) / 100,
                            },
                        ],
                    };
                }

                purchaseState = {
                    venue: {
                        id: state.sites.selectedSite?.siteId,
                        code: siteCode,
                        country: state.sites.selectedSite?.country,
                        region: region,
                        key: state.sites.selectedSite?.urlKey,
                        name: state.sites.selectedSite?.name,
                        initial_venue_key: state.sites.preselectedSite,
                    },
                    gift_voucher: undefined,
                    booking: {
                        booking_id: state.booking.booking?.bookingId,
                        ticket_quantity: state.players.selectedPlayers,
                        is_private_session: state.players.selectedPrivateUpgrade,
                        price_code: priceCode,
                    },
                    session: {
                        date: state.session.selectedDate,
                        time: state.session.selectedTime?.time,
                        package_id: state.session.selectedSession?.packageId,
                        package: state.session.selectedSession?.package.header,
                        package_name: state.session.selectedSession?.package.alias,
                        count_dates_available: state.session.openDates?.size(),
                        count_times_avaliable: state.session.sessionTimes?.length,
                        count_slots_available: state.session.selectedSession?.remainingSlots,
                    },
                    env: state.appSettings.environment,
                };
            } else {
                const charge = state.giftVoucher.charge;
                const paymentId = state.giftVoucher.charge?.relatedPaymentId;

                const currencyCode = charge?.currencyCode ?? '';
                const quantity = 1;

                let tax = charge?.tax ?? 0;
                let fee = charge?.fee ?? 0;
                let cost = charge?.cost ?? 0;
                const ticketCost = charge?.total ?? 0;
                let total = ticketCost;

                cost *= quantity;

                if (state.giftVoucher.giftVoucher != undefined) {
                    cart = {
                        transaction_id: paymentId,
                        affiliation: siteCode,
                        value: Math.round((total + Number.EPSILON) * 100) / 100,
                        currency: currencyCode,
                        tax: Math.round((tax + Number.EPSILON) * 100) / 100,
                        coupon: undefined,
                        discount: 0.0,
                        fee: fee,
                        items: [
                            {
                                item_id: 'Gift Voucher',
                                item_name: 'Gift Voucher',
                                affiliation: siteCode,
                                currency: currencyCode,
                                coupon: undefined,
                                discount: 0.0,
                                item_brand: 'Zero Latency',
                                item_category: 'Gift Voucher',
                                quantity: 1,
                                price: cost,
                            },
                        ],
                    };
                } else {
                    cart = undefined;
                }

                purchaseState = {
                    venue: {
                        id: state.sites.selectedSite?.siteId,
                        code: siteCode,
                        country: state.sites.selectedSite?.country,
                        region: region,
                        key: state.sites.selectedSite?.urlKey,
                        name: state.sites.selectedSite?.name,
                        initial_venue_key: state.sites.preselectedSite,
                    },
                    gift_voucher: {
                        gift_voucher_id: state.giftVoucher.giftVoucher?.giftVoucherId,
                        gift_voucher_value: state.giftVoucher.amount,
                        gift_voucher_currency: state.sites.selectedSite?.paypalCode,
                    },
                    booking: undefined,
                    session: undefined,
                    env: state.appSettings.environment,
                };
            }

            switch (knownAction.type) {
                case LOCATION_CHANGE: {
                    const postedAction: LocationChangeAction = action as LocationChangeAction;
                    GoogleTagManager.pageView(
                        `${postedAction.payload.location.pathname}`,
                        'Zero Latency Booking Experience',
                        state.attribution,
                        state.sites.selectedSiteGtmKey
                    );
                    break;
                }
                default: {
                    if (knownAction.googleTagManagerEvent)
                        GoogleTagManager.event(
                            knownAction.googleTagManagerEvent,
                            purchaseState,
                            cart,
                            state.attribution,
                            state.sites.selectedSiteGtmKey
                        );
                    break;
                }
            }

            // This will likely be the action itself, unless
            // a middleware further in chain changed it.
            return returnValue;
        };

    return analyticsMiddleware;
}
