import errorHelpers from '../helpers/errors';
import { ICreateGiftVoucherRequest } from '../interfaces/GiftVouchers/ICreateGiftVoucherRequest';
import { ICharge } from '../interfaces/ICharge';
import { IGiftVoucher } from '../interfaces/GiftVouchers/IGiftVoucher';
import { IGiftVoucherConfig } from '../interfaces/IGiftVoucherConfig';
import { IGiftVoucherPaymentRequest } from '../interfaces/GiftVouchers/IGiftVoucherPaymentRequest';
import { IConfirmGiftVoucherRequest } from '../interfaces/GiftVouchers/IConfirmGiftVoucherRequest';
import { IUpdateGiftVoucherRequest } from '../interfaces/GiftVouchers/IUpdateGiftVoucherRequest';
import { ICancelGiftVoucherRequest } from '../interfaces/GiftVouchers/ICancelGiftVoucherRequest';
import { IGiftVoucherState } from '../store/GiftVoucher';

class GiftVouchers {
    public static GetVoucherConfig = (siteId: number): Promise<IGiftVoucherConfig> => {
        return fetch(`giftvoucher/${siteId}/config`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IGiftVoucherConfig>);
    };

    static ReserveGiftVoucher = (
        giftVoucher: IGiftVoucherState,
        fromName: string | undefined,
        fromEmailAddress: string | undefined,
        siteId: number,
        expirationDate: string | undefined,
        adBlockEnabled: boolean
    ): Promise<IGiftVoucherPaymentRequest> => {
        const createGiftVoucherRequest: ICreateGiftVoucherRequest = {
            credit: giftVoucher.amount ?? 0,
            toEmail: giftVoucher?.toEmail,
            toName: giftVoucher?.toFullName,
            giftMessage: giftVoucher?.message,
            fromName: fromName,
            fromEmail: fromEmailAddress,
            expirationDate: expirationDate,
            adBlockEnabled: adBlockEnabled,
        };

        return fetch(`giftvoucher/${siteId}/reserve`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createGiftVoucherRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IGiftVoucherPaymentRequest>);
    };

    static ConfirmGiftVoucher = (
        charge: ICharge,
        giftVoucher: IGiftVoucher,
        paymentMethodReference: string,
        paymentMethodTypeId: number,
        paymentGatewayMetadata: any,
        adBlockDetect: boolean
    ): Promise<string> => {
        if (!giftVoucher || !charge || !giftVoucher.paymentId) throw new Error('Charge required');

        const confirmRequest: IConfirmGiftVoucherRequest = {
            emailAddress: giftVoucher.fromEmail,
            fullName: giftVoucher.fromName,
            amount: charge.total,
            currencyCode: charge.currencyCode,
            dateCreated: new Date().toISOString(),
            paymentGatewayMetadata: paymentGatewayMetadata,
            paymentMethodReference: paymentMethodReference,
            paymentMethodTypeId: paymentMethodTypeId,
            relatedPaymentId: giftVoucher.paymentId,
            siteId: giftVoucher.siteId,
            tax: charge?.tax ?? 0,
            adBlockEnabled: adBlockDetect,
        };

        console.log('SENDING CONFIRM REQUEST : with ad blocker value : ' + adBlockDetect);
        var jsn = JSON.stringify(confirmRequest);
        console.log(jsn);

        return fetch(`giftvoucher/${giftVoucher.siteId}/${giftVoucher.giftVoucherRef}/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsn,
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.text() as Promise<string>);
    };

    static CancelGiftVoucher = (
        siteId: number,
        giftVoucher: IGiftVoucher,
        charge: ICharge | undefined
    ): Promise<string> => {
        const cancelGiftVoucherRequest: ICancelGiftVoucherRequest = {
            amount: charge?.total ?? 0,
            currencyCode: charge?.currencyCode ?? 'unknown',
            dateCreated: giftVoucher.createdDateTime,
            relatedPaymentId: giftVoucher.paymentId,
            siteId: giftVoucher.siteId,
            tax: charge?.tax ?? 0,
            userAbandonedPurchase: true,
        };

        return fetch(`giftvoucher/${siteId}/${giftVoucher.giftVoucherRef}/cancel`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cancelGiftVoucherRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.text() as Promise<string>);
    };

    static UpdateGiftVoucher = (
        giftVoucher: IGiftVoucher,
        fromEmail?: string | null,
        fromName?: string | null,
        toEmail?: string | null,
        toName?: string | null,
        message?: string | null,
        amount?: number | null,
        phoneNumber?: string | null
    ): Promise<IGiftVoucherPaymentRequest> => {
        const updateGiftVoucherRequest: IUpdateGiftVoucherRequest = {
            fromEmail: fromEmail,
            fromName: fromName,
            toEmail: toEmail,
            toName: toName,
            credit: amount,
            giftMessage: message,
            phoneNumber: phoneNumber,
        };

        return fetch(`giftvoucher/${giftVoucher.siteId}/${giftVoucher.giftVoucherRef}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateGiftVoucherRequest),
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IGiftVoucherPaymentRequest>);
    };

    static GetDetails = (siteId: number, giftVoucherRef: string): Promise<IGiftVoucherPaymentRequest> => {
        return fetch(`giftvoucher/${siteId}/${giftVoucherRef}/gift-voucher`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => errorHelpers.handleErrors(response))
            .then((response) => response.json() as Promise<IGiftVoucherPaymentRequest>);
    };
}

export default GiftVouchers;
