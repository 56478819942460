const adBlockChecker = {
    IsAdBlockEnabled: async () => {
        //check if we can download an arbitrary js file labeled with a key search term 'prebid-ads'
        let prebidWasBlocked = false;
        if ((window as any).zlPrebidAdsDownloaded === undefined) {
            //console.log(`Error downloading prebin-ads file : AdBlock is Enabled`);
            prebidWasBlocked = true;
        } else {
            //console.log(`Successfully downloaded prebin-ads file : AdBlock is Disabled`);
            prebidWasBlocked = false;
        }

        //check if we are able to download the google adds js, which is often blocked by add blockers
        let googleAddsBlocked = false;
        const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        try {
            let r = new Request(googleAdUrl);
            await fetch(r).catch((_) => {
                googleAddsBlocked = true;
            });
        } catch (e) {
            googleAddsBlocked = true;
        } finally {
            //console.log(`google adds download was blocked: ${googleAddsBlocked}`);
        }

        //if either failed, assume the ad blocker is enabled
        let detected = prebidWasBlocked || googleAddsBlocked;
        console.log('Checked and adBlock detected was : ' + (detected ? 'true' : 'false'));
        return detected;
    },
};

export default adBlockChecker;
