import './session-date-picker.scss';

import { Button, Calendar, Col, Empty, Row } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SessionDateCell from './session-date-cell';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import IOpenDate, { OpenDateStatus } from '../../interfaces/IOpenDate';
import { Dictionary } from 'typescript-collections';
import 'moment/locale/en-au';

export interface ISessionDatePickerProps {
    initalDate: string | undefined;
    selectedDate: string | undefined;
    availableDates: Dictionary<string, IOpenDate> | undefined;
    monthHeaderOptions: ICalendarHeaderOption[];
    loading: boolean;
    locale: string;
    onDateChange: (date: moment.Moment) => void;
}

export interface ICalendarHeaderOption {
    label: string;
    month: number;
    year: number;
}

const SessionDatePicker: React.FunctionComponent<ISessionDatePickerProps> = (props) => {
    const { t } = useTranslation();
    const emptyLogo = `/static/zl-black-new.png`;
    const { availableDates, onDateChange, locale, loading, monthHeaderOptions, initalDate, selectedDate } = props;

    //Has the component done its initialization run yet y/n
    const [isInit, setIsInit] = useState(false);

    const [dateValue, setDateValue] = useState(moment()); //the date the rest of the app knows about
    const [visualDate, setvisualDate] = useState(moment()); //the date used to fake visual user selection

    // This use effect will help understand what is going on here via the console.
    // We had to split the date value and the visual value because we wanted users to be able to switch
    // which month was selected without the app fetching date date for the next month and auto scrolling away from the calendar.
    // Ant design controls its rendering based on the Calendar value so we had to tell the calendar a new date but not report a changed event.

    // useEffect(() => {
    //     console.log(`visually: ${visualDate.date()} / ${visualDate.month()} / ${visualDate.year()}`);
    //     console.log(`actually: ${dateValue.date()} / ${dateValue.month()} / ${dateValue.year()}`);
    // }, [dateValue, visualDate]);

    //run the first time init if required, or catch selectedDate being reset
    if (initalDate) {
        var newInitialMoment = moment(initalDate, 'YYYY-MM-DD');

        //run the first time init if the component is loading for the first time
        if (!isInit) {
            setIsInit(true);

            //set the dates to either the default 'initialDate', or the 'selectedDate' if it exists
            if (selectedDate) {
                newInitialMoment = moment(selectedDate);
            }

            //update both the data and the ui
            setDateValue(newInitialMoment);
            setvisualDate(newInitialMoment);
        } else if (!dateValue.isSame(newInitialMoment) && (!selectedDate || !dateValue.isSame(selectedDate))) {
            //otherwise if the visual date doesn't match either the initial date or the selected date reset it
            // to catch the case where the selected date is nulled out
            setDateValue(newInitialMoment);
            setvisualDate(newInitialMoment);
        }
    }

    const monthSelected = useCallback(
        (
            option: ICalendarHeaderOption,
            onChange: (value: moment.Moment) => void,
            onTypeChange: (type: CalendarMode) => void
        ) => {
            const { year, month } = option;
            const newValue = visualDate.clone().month(month).year(year);
            setvisualDate(newValue);
        },
        [visualDate]
    );

    const headerRenderer = (
        value: moment.Moment,
        type: any,
        onChange: (value: moment.Moment) => void,
        onTypeChange: (type: CalendarMode) => void
    ): React.ReactNode => {
        return (
            <section className="zl-calendar-header">
                {monthHeaderOptions.map((option: ICalendarHeaderOption) => {
                    return (
                        <Button
                            type="ghost"
                            className={`zl-month-button ${value.month() === option.month ? 'selected' : ''}`}
                            key={option.month}
                            data-month={option.month}
                            data-year={option.year}
                            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                monthSelected(option, onChange, onTypeChange);
                            }}
                        >
                            {option.label}
                        </Button>
                    );
                })}
            </section>
        );
    };

    const dateRenderer = useCallback(
        (current: moment.Moment) => {
            const availability = availableDates?.getValue(current.locale('en-au').format('YYYY-MM-DD'));
            const status = availability?.status;
            const disabled =
                availability === undefined || status === OpenDateStatus.Closed || status === OpenDateStatus.Full;
            const now = moment();
            const selected = dateValue;
            const visual = visualDate;

            return (
                <SessionDateCell
                    status={status}
                    today={current.isSame(now, 'date')}
                    past={current.isBefore(now, 'date')}
                    thisMonth={current.isSame(visual, 'month')}
                    selected={
                        selectedDate !== undefined &&
                        current.isSame(selected, 'date') &&
                        current.isSame(selected, 'month')
                    }
                    day={current.date()}
                    month={current.month()}
                    year={current.year()}
                    locale={locale}
                    disabled={disabled}
                />
            );
        },
        [availableDates, dateValue, locale, visualDate]
    );

    const disabledDate = useCallback(
        (currentDate: moment.Moment | undefined): boolean => {
            if (currentDate === undefined) return true; //no date??

            if (availableDates === undefined) return true; //no dates available

            const availability = availableDates.getValue(currentDate.locale('en-au').format('YYYY-MM-DD'));
            if (availability === undefined) return true;

            const status = availability.status;
            if (status === OpenDateStatus.Closed || status === OpenDateStatus.Full) return true;

            return false;
        },
        [availableDates]
    );

    const selectedDateChange = useCallback(
        (date?: moment.Moment | null) => {
            if (date && onDateChange) {
                setDateValue(date);
                setvisualDate(date);
                onDateChange(date);
            }
        },
        [onDateChange]
    );

    const NoAvailabilityState = (): React.ReactElement => {
        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Empty
                        description={t('sessionDatePicker.labels.noDatesAvailable')}
                        image={emptyLogo}
                        imageStyle={{ opacity: 0.1, height: 50, margin: 20 }}
                    />
                </Col>
            </Row>
        );
    };

    const EmptyState = (): React.ReactElement => {
        return (
            <Row justify="start" align="top">
                <Col span={24}>
                    <Empty
                        description={t('sessionDatePicker.labels.selectDatePlayersFirst')}
                        image={emptyLogo}
                        imageStyle={{ opacity: 0.1, height: 50, margin: 20 }}
                    />
                </Col>
            </Row>
        );
    };

    if (loading) return <LoadingSpinner />;
    if (availableDates === undefined) return <EmptyState />;
    else if (availableDates.isEmpty()) return <NoAvailabilityState />;

    return (
        <Row justify="start" align="top">
            <Col>
                <Calendar
                    className={`zl-calendar`}
                    onSelect={selectedDateChange}
                    dateFullCellRender={dateRenderer}
                    disabledDate={disabledDate}
                    fullscreen={true}
                    value={visualDate}
                    mode="month"
                    headerRender={({ value, type, onChange, onTypeChange }): React.ReactNode => {
                        return headerRenderer(value, type, onChange, onTypeChange);
                    }}
                />
            </Col>
        </Row>
    );
};

export default SessionDatePicker;
