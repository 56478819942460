import './game-package.scss';

import { Col, Collapse, Row } from 'antd';
import React from 'react';

import Experience from '../../interfaces/IExperience';
import Session from '../../interfaces/ISession';
import Site from '../../interfaces/ISite';
import GamePackageInfo from './package-info';
import GamePackageImage from './package-image';
import GamePackageHeader from './package-header';
import { useTranslation, Trans } from 'react-i18next';
import numberHelpers from '../../helpers/number';
import Text from 'antd/lib/typography/Text';
import Currency from '../currency/currency';

const { Panel } = Collapse;

export interface IGamePackageProps {
    game: Experience | undefined;
    session?: Session;
    site?: Site;
    locale: string;
}

const GameInfo: React.FunctionComponent<IGamePackageProps> = (props) => {
    const { t } = useTranslation();
    const { game, session, site, locale } = props;

    if (game == null || site == undefined) return <React.Fragment></React.Fragment>;

    const noImage = `https://zerolatencyvr.azureedge.net/booking-engine/${site.siteId}/no-package-image-header.jpg`;
    const headerImage =
        game.imageUrl ?? `https://zerolatencyvr.azureedge.net/booking-engine/packages/${game.alias.toLowerCase()}.jpg`;

    const minimumAge = game.minimumAge > 0 ? t('packagePicker.labels.ageMin', { age: game.minimumAge }) : undefined;

    return (
        <Row justify="start" align="top" className="zl-session">
            <Col span={24}>
                <Row justify="start" align="bottom">
                    <Col span={24} className="session-header">
                        <GamePackageImage
                            session={session}
                            imageUrl={headerImage}
                            fallbackImage={noImage}
                            packageName={game.name}
                            modalOkText={t('packagePicker.labels.close')}
                            videoUrl={game.videoUrl}
                        />
                        <GamePackageHeader
                            tagline={
                                game.attractionTypeId > 1
                                    ? game.attractionTypeName
                                    : t('packagePicker.labels.virtualRealityExperience')
                            }
                            packageName={game.name}
                        />
                    </Col>
                </Row>
                <Row justify="start" align="middle" className="mobile-details-row">
                    <Col span={24} className="package-details">
                        <GamePackageInfo
                            playersValue={t('packagePicker.labels.playersMinMax', {
                                min: `${numberHelpers.toNumber(locale, game.minimumPlayers)}`,
                                max: `${numberHelpers.toNumber(locale, game.maximumPlayers)}`,
                            })}
                            playersLabel={t('packagePicker.labels.players')}
                            ageValue={minimumAge}
                            ageLabel={t('packagePicker.labels.age')}
                            gameInfo={true}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default React.memo(GameInfo);
