const regionConverter = {
    regionIdToString: (regionId: number): string => {
        if (regionId === 1) {
            return 'Asia-Pacific';
        } else if (regionId === 2) {
            return 'Europe, Middle East, and Africa';
        } else if (regionId === 3) {
            return 'Americas';
        } else {
            return 'Unknown';
        }
    },
};

export default regionConverter;
